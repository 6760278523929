import React, { useState, useEffect } from 'react';
import * as Formatar from '../../functions/Format';
import * as Funcoes from '../../functions/functions';
import {
  Button,
  Container,
  Paper,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Snackbar,
  Alert,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import moment from 'moment';
import Papa from 'papaparse';

const Relatorios = ({ user, checkAuthentication }) => {
  const [dataDe, setDataDe] = useState(moment().subtract(1, 'days').format('YYYY-MM-DD'));
  const [dataAte, setDataAte] = useState(moment().format('YYYY-MM-DD'));
  const [extrato, setExtrato] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [selectedManager, setSelectedManager] = useState(0);
  const [selectedManagerMovId, setSelectedManagerMovId] = useState('');
  const [managers, setManagers] = useState([]);
  const [selectedTypeMov, setSelectedTypeMov] = useState(0);
  const [typemov, setTypeMov] = useState([]);
  const [selectedOperation, setSelectedOperation] = useState(0);
  const [operation] = useState([{ id: 0, chave: "todas" }, { id: 1, chave: "positiva" }, { id: 2, chave: "negativa" }]);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [showExportButton, setShowExportButton] = useState(false);
  const [chosenbank, setSelectedBank] = useState(0);



  useEffect(() => {
    async function fetchDatacheck() {
      await checkAuthentication();
    }
    fetchDatacheck();
  }, []);

  useEffect(() => {
    async function fetchDataManager() {
      const responseManager = await Funcoes.getManager();
      setManagers(responseManager.managerSummary || []);

      const responseTypeMov = await Funcoes.getypeMov();
      setTypeMov(responseTypeMov.types || []);
    }
    fetchDataManager();
  }, []);

  useEffect(() => {
    setShowExportButton(false);
  }, [dataDe, dataAte, selectedManager, selectedTypeMov, selectedOperation]);

  const buscarExtrato = async (
    newPage = page,
    newRowsPerPage = rowsPerPage,
    managerId = selectedManager,
    typemovement = selectedTypeMov,
    operation = selectedOperation,
    managerMovId = selectedManagerMovId,
    csvT = false
  ) => {
    try {
      const fromDate = moment(dataDe).startOf('day').format('YYYY-MM-DD HH:mm:ss');
      const toDate = moment(dataAte).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      const bankMap = {
        0: "",
        1: "qesh",
        2: "bmb"
      };

      let bank = bankMap[chosenbank] || ""

      const extratoResponse = await Funcoes.fetchExtratoMaster(
        managerId || "",
        fromDate,
        toDate,
        newPage,
        newRowsPerPage,
        typemovement || "",
        operation || "",
        managerMovId,
        csvT,
        bank
      );
      console.log(extratoResponse)

      if (!csvT) {
        setExtrato(extratoResponse.data);
        setTotalItems(extratoResponse.totalItems);
        setTotalValue(extratoResponse.totalValue);
        setShowExportButton(extratoResponse.data.length > 0);
        setPage(newPage);
        setRowsPerPage(newRowsPerPage);
      } else {
        exportToCSV(extratoResponse.data);
      }
    } catch (error) {
      setSnackbarMessage('Erro ao buscar movimentação');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const exportToCSV = (extratoCSV) => {
    const csvData = extratoCSV.map((item) => ({
      "Mov ID": item.id,
      "Banco": item.chosenbank,
      Conta: item.originAccountName === "N/A" ? item.destinationAccountName : item.originAccountName,
      "Documento": item.originAccountdocument === "N/A" ? item.destinationAccountdocument : item.originAccountdocument,
      Descrição: item.description,
      "Valor (R$)": Formatar.converterCentavosParaReais(item.value),
      "Tipo de Operação": item.typeMovement,
      "Data/Hora": Formatar.formatarDateAnoHoraSegundo(item.createdAt),
    }));

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'extrato_movimentacoes.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setLoadingCSV(false);
  };

  const handleExportCSV = () => {
    setLoadingCSV(true);
    buscarExtrato(page, rowsPerPage, selectedManager, selectedTypeMov, selectedOperation, selectedManagerMovId, true);
  };

  const handleManagerChange = (selectedValue) => {
    setSelectedManager(selectedValue);
    setSelectedManagerMovId(managers.find((item) => item.managerId === selectedValue)?.idAccount || '');
  };

  const handleChangePage = (event, newPage) => {
    buscarExtrato(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setPage(0);
    buscarExtrato(0, newRowsPerPage);
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 12, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            {`Extrato de Movimentações: Total no período ${Formatar.converterCentavosParaReais(totalValue)}`}
          </Typography>
        </Grid>

        {/* Filtros e Campos de Data */}
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <InputLabel id="manager-select-label">Selecione um Gerente</InputLabel>
            <Select
              labelId="manager-select-label"
              value={selectedManager}
              onChange={(e) => handleManagerChange(e.target.value)}
              fullWidth
              label="Selecione um Gerente"
              displayEmpty
            >
              <MenuItem value={0}>TODOS</MenuItem>
              {managers.map((item) => (
                <MenuItem key={item.idAccount} value={item.managerId}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <span>{item.managerName.toUpperCase()}</span>
                    <span>{`R$: ${Formatar.converterCentavosParaReais(item.balance)}`}</span>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <InputLabel id="type-mov-select-label">Tipo de Movimentação</InputLabel>
            <Select
              labelId="type-mov-select-label"
              value={selectedTypeMov}
              onChange={(e) => {
                setSelectedTypeMov(e.target.value);
              }}
              fullWidth
              label="Tipo de Movimentação"
              displayEmpty
            >
              <MenuItem value={0}>TODAS</MenuItem>
              {typemov.map((item, index) => (
                <MenuItem key={index} value={item.key}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <span>{item.description.toUpperCase()}</span>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <InputLabel id="operation-select-label">Tipo de Operação</InputLabel>
            <Select
              labelId="operation-select-label"
              value={selectedOperation}
              onChange={(e) => {
                setSelectedOperation(e.target.value);
              }}
              fullWidth
              label="Tipo de Operação"
              displayEmpty
            >
              {operation.map((item, index) => (
                <MenuItem key={index} value={item.id}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <span>{item.chave.toUpperCase()}</span>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth>
            <InputLabel id="bank-select-label">Banco</InputLabel>
            <Select
              labelId="bank-select-label"
              value={chosenbank}
              onChange={(e) => {
                setSelectedBank(e.target.value);
              }}
              fullWidth
              label="Banco"
              displayEmpty
            >
              <MenuItem value={0}>TODOS</MenuItem>
              <MenuItem value={1}>QESH</MenuItem>
              <MenuItem value={2}>BMB</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Campos de Data */}
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Data De"
            type="date"
            value={dataDe}
            onChange={(e) => setDataDe(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Data Até"
            type="date"
            value={dataAte}
            onChange={(e) => setDataAte(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        {/* Botões */}
        <Grid item container spacing={2} >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => buscarExtrato()}
              sx={{
                padding: '8px 16px',
                minHeight: '40px', // Altura padrão
              }}
            >
              Buscar Movimentações
            </Button>
          </Grid>

          {showExportButton && (
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleExportCSV}
                startIcon={loadingCSV && <CircularProgress size={20} />}
                disabled={loadingCSV}
                sx={{
                  padding: '8px 16px',
                  minHeight: '40px', // Altura padrão
                }}
              >
                {loadingCSV ? 'Exportando...' : 'Exportar para CSV'}
              </Button>
            </Grid>
          )}
        </Grid>


        {/* Tabela de Dados */}
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="extrato table">
              <TableHead>
                <TableRow>
                  <TableCell>Mov ID</TableCell>
                  <TableCell>Conta</TableCell>
                  <TableCell>Documento</TableCell>
                  <TableCell>Descrição</TableCell>
                  <TableCell align="right">Valor R$</TableCell>
                  <TableCell>Data/Hora</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {extrato.map((item) => (
                  <TableRow key={item.id} sx={{ backgroundColor: item.value > 0 ? 'lightgreen' : 'lightcoral' }}>
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{item.originAccountName === "N/A" ? item.destinationAccountName : item.originAccountName}</TableCell>
                    <TableCell>{item.originAccountdocument === "N/A" ? item.destinationAccountdocument : item.originAccountdocument}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell align="right">{Formatar.converterCentavosParaReais(item.value)}</TableCell>
                    <TableCell>{Formatar.formatarDateAnoHoraSegundo(item.createdAt)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[50, 100]}
            component="div"
            count={totalItems}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>

      {/* Snackbar */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{
          width: '100%',
          fontSize: '1.50rem', // Aumenta em 15%
        }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>

  );
};

export default Relatorios;
