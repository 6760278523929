import React, { useState, useEffect } from 'react';
import {
    Box,
    Grid,
    Typography,
    TextField,
    Button,
    Container,
    CircularProgress,
    Snackbar,
    Alert,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import InputMask from "react-input-mask";
import * as Funcoes from '../../functions/functions';
import * as Format from "../../functions/Format";
import { fetchContas, fetchKYC, fetchCEP, createAccount } from './utils/apiCalls';
import * as Formatar from './utils/format';
import axios from 'axios';
import { bancos } from '../../components/isbp'; // Importa a lista de bancos com ISPB
import { ceps } from '../../components/ceps'; // Importa a lista de bancos com ISPB

// Função para gerar email fictício
const gerarEmailFicticio = (nome) => {
    const provedoresValidos = ['gmail.com', 'yahoo.com', 'outlook.com', 'hotmail.com', 'icloud.com'];
    const provedorAleatorio = provedoresValidos[Math.floor(Math.random() * provedoresValidos.length)];
    return `${nome.replace(/\s+/g, '').toLowerCase()}@${provedorAleatorio}`;
};

// Função para gerar telefone fictício
const gerarTelefoneFicticio = () => {
    const DDDs = ['11', '21', '31', '41', '51', '61', '71', '81', '91'];
    const DDD = DDDs[Math.floor(Math.random() * DDDs.length)];
    const numeroBase = Math.floor(Math.random() * 900000000 + 100000000).toString();
    return `${DDD}${numeroBase}`;
};


// Função para gerar endereço fictício com CEP válido
const gerarEnderecoFicticio = async () => {
    let enderecoValido = await Funcoes.zipcode();
    return {
        cep: enderecoValido.cep,
        endereco: enderecoValido.logradouro || '',
        bairro: enderecoValido.bairro || '',
        cidade: enderecoValido.estado || enderecoValido.localidade || '',
        estado: enderecoValido.uf || '',
        numero: Math.floor(Math.random() * 1000) + 1,
        complemento: enderecoValido.complemento
    };
};

// Função para buscar o nome do banco pelo ISPB
const buscarNomeBancoPorISPB = (ispb) => {
    const banco = bancos.find((banco) => banco.ispb === ispb);
    return banco ? banco.name : 'Banco não encontrado';
};

const generateRandomBirthDate = () => {
    const currentDate = new Date();
    const minYear = currentDate.getFullYear() - 100; // Limite de idade máxima (100 anos atrás)
    const maxYear = currentDate.getFullYear() - 18;  // Limite de idade mínima (18 anos atrás)

    // Gera um ano aleatório entre minYear e maxYear
    const randomYear = Math.floor(Math.random() * (maxYear - minYear + 1)) + minYear;

    // Gera um mês aleatório entre 0 e 11 (Janeiro a Dezembro)
    const randomMonth = Math.floor(Math.random() * 12);

    // Gera um dia aleatório entre 1 e 28 (para evitar problemas com meses que têm menos de 31 dias)
    const randomDay = Math.floor(Math.random() * 28) + 1;

    // Cria a data aleatória
    const randomDate = new Date(randomYear, randomMonth, randomDay);

    // Formata a data no formato YYYY-MM-DD
    return randomDate.toISOString().split('T')[0];
};

const isDateFormatted = (dateString) => {
    // Expressão regular para verificar se a data está no formato YYYY-MM-DD
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
    return dateFormatRegex.test(dateString);
};

const Cadastrar = ({ tariff, checkAuthentication }) => {
    // Estados separados para cada campo
    const [documento, setDocumento] = useState('');
    const [loading, setLoading] = useState(true);
    const [showError, setShowError] = useState(false);
    const [mensagem, setMensagem] = useState('');
    const [pesquisarCPF, setPesquisarCPF] = useState(false);
    const [loadCadastrar, setLoadCadastrar] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [nomeBanco, setNomeBanco] = useState('');
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [tariffList, setTariff] = useState([]);
    const [chosenbank, setChosenbank] = useState(localStorage.getItem('chosenbank')); // Estado inicial para banco selecionado (qesh)
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setLoading(false);
    }, []);

    useEffect(() => {
        async function fetchDatacheck() {
            await checkAuthentication();
            await getTariffUser();
        }
        fetchDatacheck();
    }, []);

    const getTariffUser = async () => {
        const tariff = await Funcoes.getTariffUser(user.id);
        //console.log(tariff);
        setTariff(tariff);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSearchPixKey = async (cpf) => {
        try {
            const response = await Funcoes.consultarPix({ userId: user.id, chavePix: cpf, typekey: 1, chosenbank });
            const detalhesPix = response?.data;
            const nomeBanco = buscarNomeBancoPorISPB(detalhesPix?.ispb || '');

            const status = !!detalhesPix?.id; // Verifica se a chave Pix foi encontrada

            // Retornando um objeto com o status e nomeBanco
            return { status, nomeBanco };
        } catch (error) {
            return { status: false, nomeBanco: '' }; // Em caso de erro, retorna status false e nomeBanco vazio
        }
    };

    const buscarKYC = async () => {
        const tarifaItem = tariffList.find((item) => item.tariffkey === "tarifa_criar_conta_filha");

        if (user.accountData[0]?.profile !== 'master') {

            const multipleBalance = Array.isArray(user?.configData?.multiplebalance)
                ? user.configData.multiplebalance
                : JSON.parse(user?.configData?.multiplebalance || '[]');

            const balanceData = multipleBalance.find(item => item.bank === chosenbank);
            const balance = balanceData?.balance || 0;

            if (balance < tarifaItem.value) {
                setSnackbarOpen(true);
                setSnackbarMessage('Saldo insuficiente');
                setSnackbarSeverity('error');
                setPesquisarCPF(false);
                return;
            }
        }

        setPesquisarCPF(true);
        setShowError(false);

        if (!documento || !Formatar.validar_cpf(documento)) {
            setSnackbarOpen(true);
            setSnackbarMessage('Por favor, verifique o CPF.');
            setSnackbarSeverity('error');
            setPesquisarCPF(false);
            return;
        }


        if (user.accountData[0]?.profile !== 'master' && user.accountData[0]?.profile !== 'gerenteMaster' && user.accountData[0]?.profile !== 'gerenteMasterEVP') {
            // Chama a função para buscar a chave Pix e obter status e nomeBanco
            const { status, nomeBanco } = await handleSearchPixKey(documento.replace(/\D/g, ''));

            // Verifica se a chave Pix já existe
            if (status) {
                setSnackbarOpen(true);
                setSnackbarMessage(`CPF já cadastrado no ${nomeBanco}`);
                setSnackbarSeverity('error');
                setPesquisarCPF(false);
                return;
            }
        }

        try {
            const response = await fetchKYC(documento);

            if (response?.dadosCadastro?.status) {
                const dadosCompletos = {
                    nome: response?.dadosCadastro?.nome,
                    documento,
                    nomeMae: response?.dadosCadastro?.nomeMae,
                    dataNascimento: response?.dadosCadastro?.dataNascimento || generateRandomBirthDate(),
                    ...await gerarEnderecoFicticio(),
                    email: response?.dadosCadastro?.email || gerarEmailFicticio(response?.dadosCadastro?.nome),
                    sexo: response?.dadosCadastro?.sexo,
                    telefone: response?.dadosCadastro?.telefone || gerarTelefoneFicticio()
                };
                criarConta(dadosCompletos);
            } else {
                // Caso o status seja falso, exibe mensagem de erro
                setSnackbarOpen(true);
                setSnackbarMessage(response?.message || 'Status do cadastro inválido.');
                setSnackbarSeverity('error');
                setPesquisarCPF(false);
            }
        } catch (error) {
            setSnackbarOpen(true);
            setSnackbarMessage('Erro ao buscar KYC');
            setSnackbarSeverity('error');
            setPesquisarCPF(false);
        }
    };

    const criarConta = async (dadosCompletos) => {
        setLoadCadastrar(true);

        if (!dadosCompletos.nome || !dadosCompletos.documento) {
            setSnackbarOpen(true);
            setSnackbarMessage('Por favor, preencha todos os campos obrigatórios.');
            setSnackbarSeverity('error');
            setLoadCadastrar(false);
            setPesquisarCPF(false);
            return;
        }

        // Verifica se o perfil do usuário é 'master' e se os campos obrigatórios estão preenchidos
        if (user.accountData[0]?.profile === 'master') {
            if (!documento || !Formatar.validar_cpf(documento)) {
                setSnackbarOpen(true);
                setSnackbarMessage('Por favor, preencha um CPF válido.');
                setSnackbarSeverity('error');
                setLoadCadastrar(false);
                setPesquisarCPF(false);
                return;
            }

            if (!telefone) {
                setSnackbarOpen(true);
                setSnackbarMessage('Por favor, preencha o telefone.');
                setSnackbarSeverity('error');
                setLoadCadastrar(false);
                setPesquisarCPF(false);
                return;
            }

            if (!email) {
                setSnackbarOpen(true);
                setSnackbarMessage('Por favor, preencha o e-mail.');
                setSnackbarSeverity('error');
                setLoadCadastrar(false);
                setPesquisarCPF(false);
                return;
            }
        }


        // Adiciona telefone e e-mail ao objeto, se forem preenchidos
        dadosCompletos.telefone = telefone || gerarTelefoneFicticio();
        dadosCompletos.email = email || gerarEmailFicticio(dadosCompletos.nome);

        try {

            const dataNascimento = dadosCompletos.dataNascimento;

            // Verifica se a data está formatada, caso não, formata a data
            const dataFormatada = isDateFormatted(dataNascimento)
                ? dataNascimento
                : Formatar.formatarDate(dataNascimento);

            const userData = {
                document: dadosCompletos.documento.replace(/\D/g, ""),
                name: dadosCompletos.nome,
                telephone: dadosCompletos.telefone.replace(/\D/g, ""),
                mother: dadosCompletos.nomeMae,
                father: '',
                birth: dataFormatada,
                sex: dadosCompletos.sexo
            };

            const addressData = {
                zipcode: dadosCompletos.cep.replace(/\D/g, ""),
                address: dadosCompletos.endereco,
                number: dadosCompletos.numero,
                complement: dadosCompletos.complemento,
                district: dadosCompletos.bairro,
                city: dadosCompletos.cidade,
                state: dadosCompletos.estado
            };

            const accountData = {
                managerId: user.id,
                profile: user.accountData[0]?.profile === 'master' ? 'maneger' : 'bet'
            };


            const response = await createAccount({
                demand: user.accountData[0]?.profile === 'master' ? 0 : 1,
                tariffkey: "tarifa_criar_conta_filha",
                email: dadosCompletos.email,
                userData,
                addressData,
                accountData,
                chosenbank
            });

            console.log(response);

            if (response.success) {
                setTelefone('');
                setEmail('');
                setDocumento('');
                setSnackbarOpen(true);
                setSnackbarMessage('Conta criada com sucesso!');
                setSnackbarSeverity('success');
                setLoadCadastrar(false);
                setPesquisarCPF(false);
                await checkAuthentication();
            }
            else {
                let erro = "Erro ao criar conta";
                if (response.status == 500) {
                    if (response.message == '"Age greater than 60 years"') {
                        erro = "Idade superior a 60 anos";
                    }

                    if (response.message == 'Request failed with status code 409') {
                        erro = "A conta já existe";
                    }

                    if (response.message == '"Account already exists"') {
                        erro = "A conta já existe";
                    }

                    if (response.message == '"Invalid document"') {
                        erro = "Documento inválido";
                    }


                }
                setSnackbarOpen(true);
                setSnackbarMessage(erro);
                setSnackbarSeverity('error');
                setLoadCadastrar(false);
                setPesquisarCPF(false);
            }
        } catch (error) {
            console.log(error);
            let erro = "Erro ao criar conta";
            if (error.status == 500) {
                if (error.message == '"Age greater than 60 years"') {
                    erro = "Idade superior a 60 anos";
                }

                if (response.message == 'Request failed with status code 409') {
                    erro = "A conta já existe";
                }

                if (response.message == '"Account already exists"') {
                    erro = `A conta já existe na LQ ${chosenbank}`;
                }
            }

            setSnackbarOpen(true);
            setSnackbarMessage(erro);
            setSnackbarSeverity('error');
            setLoadCadastrar(false);
            setPesquisarCPF(false);
        }
    };


    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
                <Typography variant="h4" sx={{ ml: 2 }}>Carregando...</Typography>
            </Box>
        );
    }


    const handleConfirm = () => {
        // Fecha o diálogo e chama a função buscarKYC
        setOpen(false);
        buscarKYC();
    };

    const handleCancel = () => {
        // Fecha o diálogo sem executar a ação
        setOpen(false);
    };

    const handleButtonClick = () => {
        // Abre o diálogo de confirmação
        setOpen(true);
    };

    return (
        <Container
            maxWidth="xl"
            sx={{
                mt: 14,
                mb: 4,
                paddingTop: 10,
            }}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography
                            variant="h6"
                            gutterBottom
                            sx={{
                                fontSize: { xs: '1em', sm: '1.25em' },
                            }}
                        >
                            {'Preencha o CPF para cadastrar um apostador. Tarifa para criar a conta ' +
                                (() => {
                                    const tarifaItem = tariffList.find((item) => item.tariffkey === 'tarifa_criar_conta_filha');
                                    return tarifaItem ? Format.converterCentavosParaReais(tarifaItem.value) : '';
                                })()}
                        </Typography>
                    </Box>
                    <Box sx={{ flexGrow: 1, mb: 4 }}>
                        {/* Inputs em uma linha ou empilhados em telas pequenas */}
                        <Grid container spacing={2} justifyContent="flex-start">
                            <Grid item xs={12} md={4}>
                                <InputMask mask="999.999.999-99" value={documento} onChange={(event) => setDocumento(event.target.value)}>
                                    {(inputProps) => (
                                        <TextField
                                            size="small"
                                            {...inputProps}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            label="CPF"
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: 5,
                                                    fontSize: { xs: '1em', sm: '1.25em' },
                                                },
                                            }}
                                        />
                                    )}
                                </InputMask>
                            </Grid>

                            {user.accountData[0]?.profile === 'master' && (
                                <>
                                    <Grid item xs={12} md={4}>
                                        <InputMask
                                            mask="(99) 99999-9999"
                                            value={telefone}
                                            onChange={(event) => setTelefone(event.target.value)}
                                        >
                                            {(inputProps) => (
                                                <TextField
                                                    size="small"
                                                    {...inputProps}
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                    label="Telefone"
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: 5,
                                                            fontSize: { xs: '1em', sm: '1.25em' },
                                                        },
                                                    }}
                                                />
                                            )}
                                        </InputMask>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            size="small"
                                            value={email}
                                            onChange={(event) => setEmail(event.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            label="E-mail"
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: 5,
                                                    fontSize: { xs: '1em', sm: '1.25em' },
                                                },
                                            }}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>

                        {/* Botão */}
                        <Grid container justifyContent="flex-start" sx={{ mt: 2 }}>
                            <Grid item xs={12} md={4}>
                                <Button
                                    size="small"
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                        height: '46px',
                                        fontSize: { xs: '1em', sm: '1.25em' },
                                        borderRadius: 5,
                                    }}
                                    color="success"
                                    onClick={handleButtonClick}
                                    disabled={pesquisarCPF || loadCadastrar || user?.configData?.cancreateaccount === 0}
                                >
                                    {pesquisarCPF ? <CircularProgress size={28} /> : 'Cadastrar'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                {/* Diálogo de Confirmação */}
                <Dialog open={open} onClose={handleCancel} maxWidth="md" fullWidth>
                    <DialogTitle sx={{ fontSize: { xs: '1em', sm: '1.25em' } }}>Confirmação</DialogTitle>
                    <DialogContent>
                        <Typography
                            variant="h6"
                            sx={{
                                textAlign: 'left',
                                fontSize: { xs: '1em', sm: '1.25em' },
                            }}
                        >
                            TEM CERTEZA QUE DESEJA CADASTRAR A CONTA NA LIQUIDANTE{' '}
                            <Typography
                                component="span"
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: { xs: '1.1em', sm: '1.38em' },
                                    display: 'inline',
                                }}
                            >
                                {chosenbank.toLocaleUpperCase()}
                            </Typography>
                            ?
                        </Typography>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'space-between', padding: '18px' }}>
                        <Button
                            onClick={handleCancel}
                            color="error"
                            variant="outlined"
                            sx={{
                                width: '47.5%',
                                fontSize: { xs: '1em', sm: '1.25em' },
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            onClick={handleConfirm}
                            color="success"
                            variant="contained"
                            sx={{
                                width: '47.5%',
                                fontSize: { xs: '1em', sm: '1.25em' },
                            }}
                        >
                            Confirmar
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Erro */}
                {showError && (
                    <Typography
                        color="error"
                        sx={{
                            fontSize: { xs: '1em', sm: '1.25em' },
                        }}
                    >
                        {mensagem}
                    </Typography>
                )}

                {/* Snackbar */}
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert
                        onClose={handleSnackbarClose}
                        severity={snackbarSeverity}
                        sx={{
                            width: '100%',
                            fontSize: { xs: '1em', sm: '1.25em' },
                        }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Grid>
        </Container>

    );
};

export default Cadastrar;
