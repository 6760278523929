import React, { useState, useEffect } from 'react';
import * as Funcoes from '../../functions/functions';
import {
  Button,
  Container,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Select,
  Snackbar,
  Alert,
  TextField,
  CircularProgress
} from '@mui/material';
import { bancos } from '../../components/isbp'; // Lista de bancos com ISPB
import * as Format from "../../functions/Format";

const Transferencia = ({ tariff: initialTariff, checkAuthentication }) => {
  const [pixKeys, setPixKeys] = useState([]); // Chaves Pix disponíveis
  const [selectedPixKey, setSelectedPixKey] = useState(''); // Chave Pix selecionada
  const [pixDetails, setPixDetails] = useState(null); // Detalhes da chave Pix após consulta
  const [transferAmount, setTransferAmount] = useState(''); // Valor da transferência (em reais)
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Controle do Snackbar
  const [snackbarMessage, setSnackbarMessage] = useState(''); // Mensagem do Snackbar
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Severidade do Snackbar
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [tariff, setTariff] = useState([]);
  const [loading, setLoading] = useState(false); // Carregamento para consulta da chave Pix
  const [loadingTransfer, setLoadingTransfer] = useState(false); // Carregamento para transferência
  const [chosenbank, setChosenbank] = useState(localStorage.getItem('chosenbank'));

  useEffect(() => {
    async function fetchDatacheck() {
      await checkAuthentication();
      await getTariffUser();
    }
    fetchDatacheck();
  }, []);

  // Filtra as chaves Pix com status = 2
  useEffect(() => {
    console.log(user?.configData);
    const filteredPixKeys = user?.configData?.keypix?.length >= 1 
      ? JSON.parse(user.configData.keypix).filter((key) => key.status == 2) 
      : [];
    setPixKeys(filteredPixKeys);
  }, [user]);

  const getTariffUser = async () => {
    const tariff = await Funcoes.getTariffUser(user.id);
    setTariff(tariff);
  };

  // Função para buscar o nome do banco com base no ISPB
  const buscarNomeBancoPorISPB = (ispb) => {
    const bancoEncontrado = bancos.find((banco) => banco.ispb === ispb);
    return bancoEncontrado ? bancoEncontrado.name : 'Banco não encontrado';
  };

  // Consulta os detalhes da chave Pix selecionada
  const handleSearchPixKey = async () => {
    if (!selectedPixKey) {
      setSnackbarMessage('Selecione uma chave Pix');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);

    try {
      const pixDetailResponse = await Funcoes.consultarPix({
        userId: user.id,
        chavePix: selectedPixKey,
        typekey: 1,
        chosenbank
      });
      const detalhesPix = pixDetailResponse.data;
      const nomeBanco = buscarNomeBancoPorISPB(detalhesPix.ispb);
      setPixDetails({ ...detalhesPix, nomeBanco });
    } catch (error) {
      setSnackbarMessage('Erro ao consultar chave Pix');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  // Formata o valor conforme o usuário digita (em reais)
  const handleAmountChange = (e) => {
    let value = e.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
    if (value.length > 10) {
      value = value.slice(0, 10);
    }
    // Converte de centavos para reais com duas casas decimais
    const floatValue = (parseInt(value, 10) / 100).toFixed(2);
    setTransferAmount(floatValue);
  };

  // Retorna o saldo disponível (em centavos) para o banco escolhido
  const getAvailableBalance = () => {
    if (user?.configData?.multiplebalance) {
      const bankData = user.configData.multiplebalance.find(item => item.bank === chosenbank);
      if (bankData) return bankData.balance;
    }
    return user?.configData?.balance || 0;
  };

  const handleTransfer = async () => {
    if (!transferAmount || !pixDetails) {
      setSnackbarMessage('Insira um valor válido para transferência');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    // Converte o valor da transferência para centavos para comparação
    const transferAmountCents = parseFloat(transferAmount) * 100;
    const availableBalanceCents = getAvailableBalance();

    if (transferAmountCents > availableBalanceCents) {
      setSnackbarMessage('Saldo insuficiente para transferência');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    setLoadingTransfer(true);

    try {
      const response = await Funcoes.realizarTransferencia({
        user,
        pixKey: pixDetails,
        amount: parseFloat(transferAmount), // Valor em reais
        demand: user.accountData[0]?.profile === 'master' ? 0 : 1,
        tariffkey: "tarifa_saque_conta_gerente",
        chosenbank
      });

      if (response.sucesso) {
        await checkAuthentication();
        setSnackbarMessage('Transferência realizada com sucesso!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setSelectedPixKey('');
        setPixDetails(null);
        setTransferAmount('');
        setLoadingTransfer(false);
      } else {
        setSnackbarMessage('Erro ao realizar transferência');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setLoadingTransfer(false);
      }
    } catch (error) {
      setSnackbarMessage('Erro ao realizar transferência');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setLoadingTransfer(false);
    }
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 17, mb: 4 }}>
      <Grid container spacing={3}>
        {/* Título */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            {'Transferência via Chave Pix. Tarifa para Saque ' +
              (() => {
                if (Array.isArray(tariff)) {
                  const tarifaItem = tariff.find((item) => item.tariffkey === 'tarifa_saque_conta_gerente');
                  if (tarifaItem) {
                    return tarifaItem.typeofcharge === 1
                      ? Format.converterCentavosParaReais(tarifaItem.value)
                      : Format.converterCentavosEmPorcentagem(tarifaItem.value) + '%';
                  }
                }
                return '';
              })()}
          </Typography>
        </Grid>
  
        {/* Select de Chave Pix */}
        <Grid item xs={12} sm={8}>
          <Select
            value={selectedPixKey}
            onChange={(e) => setSelectedPixKey(e.target.value)}
            fullWidth
            displayEmpty
          >
            <MenuItem value="" disabled>
              Selecione uma chave Pix
            </MenuItem>
            {pixKeys.map((key) => (
              <MenuItem key={key.keypix} value={key.keypix}>
                {key.keypix}
              </MenuItem>
            ))}
          </Select>
        </Grid>
  
        {/* Botão de Pesquisa */}
        <Grid item xs={12} sm={4}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSearchPixKey}
            disabled={!selectedPixKey || loading}
            sx={{
              height: '56px',
            }}
          >
            {loading ? <CircularProgress size={24} /> : 'Pesquisar Chave Pix'}
          </Button>
        </Grid>
  
        {/* Detalhes da Chave Pix */}
        {pixDetails && (
          <>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                Detalhes da Chave Pix:
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Proprietário</TableCell>
                      <TableCell>Banco</TableCell>
                      <TableCell>Conta</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{pixDetails.ownerName}</TableCell>
                      <TableCell>{pixDetails.nomeBanco}</TableCell>
                      <TableCell>{`${pixDetails.accountBranch} - ${pixDetails.accountNumber}`}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
  
            {/* Input para Valor da Transferência */}
            <Grid item xs={12}>
              <TextField
                label="Valor da Transferência"
                type="text"
                value={transferAmount}
                onChange={handleAmountChange}
                fullWidth
              />
              <Typography variant="caption" color="textSecondary">
                Saldo disponível: {Format.converterCentavosParaReais(getAvailableBalance())}
              </Typography>
            </Grid>
  
            {/* Botão para Realizar Transferência */}
            <Grid item xs={12} sm={4}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleTransfer}
                disabled={loadingTransfer || user?.configData?.managercanwithdraw === 0}
                sx={{
                  height: '56px',
                }}
              >
                {loadingTransfer ? <CircularProgress size={24} /> : 'Realizar Transferência'}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
  
      {/* Snackbar para feedback */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Transferencia;
