import React, { useState, useEffect } from 'react';
import {
  Button,
  Paper,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as Funcoes from '../../functions/functions';
import * as Formatar from '../../functions/Format';
import { fetchContas } from './apiCalls';

const ConsultarGerentes = ({ handleSnackbar, loading, setLoading, checkAuthentication, user }) => {
  // Estado para gerentes:
  const [managers, setManagers] = useState([]);
  const [selectedManager, setSelectedManager] = useState(0);
  const [selectedManagerMovId, setSelectedManagerMovId] = useState('');

  // Estados para controle dos bloqueios baseados no gerente selecionado:
  // true = Bloqueado, false = Desbloqueado.
  const [statusCriacaoConta, setStatusCriacaoConta] = useState(false);
  const [statusSaqueGerente, setStatusSaqueGerente] = useState(false);
  const [statusTransferencia, setStatusTransferencia] = useState(false);
  const [statusPagamentoQR, setStatusPagamentoQR] = useState(false);

  // Estados para listagem das contas do gerente:
  const [tabelaFiltro, setTabelaFiltro] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [totalItems, setTotalItems] = useState(0);

  const [chosenbank, setChosenbank] = useState(localStorage.getItem('chosenbank'));

  useEffect(() => {
    async function fetchDataManager() {
      try {
        const responseManager = await Funcoes.getManager();
        console.log(responseManager);
        setManagers(responseManager.managerSummary || []);
      } catch (error) {
        handleSnackbar('Erro ao buscar gerentes', 'error');
      }
    }
    fetchDataManager();
  }, []);

  useEffect(() => {
    async function fetchDatacheck() {
      if (checkAuthentication) {
        await checkAuthentication();
      }
    }
    fetchDatacheck();
  }, [checkAuthentication]);

  useEffect(() => {
    if (selectedManager !== 0) {
      // Atualiza os estados de bloqueio com base no gerente selecionado.
      const found = managers.find((item) => item.managerId === selectedManager);
      if (found) {
        setSelectedManagerMovId(found.managerId);
        // Se o campo for 1 (habilitado), consideramos desbloqueado (false) e se for 0, bloqueado (true)
        setStatusCriacaoConta(found.cancreateaccount === 0);
        setStatusSaqueGerente(found.managercanwithdraw === 0);
        setStatusTransferencia(found.cantransfer === 0);
        setStatusPagamentoQR(found.canwithdraw === 0);
      }
      fetchData();
    } else {
      setTabelaFiltro([]);
      setTotalItems(0);
    }
  }, [selectedManager, managers]);

  const fetchData = async (newPage = page, newRowsPerPage = rowsPerPage, sortField = orderBy, sortOrder = order, onlyManagerAccounts = false) => {
    try {
      if (setLoading) setLoading(true);
      // Ajusta o campo de ordenação se necessário
      if (sortField === "configData.balance") sortField = "balance";
      if (sortField === "id") sortField = "user_createdAt";
      if (sortField === "userData.name") sortField = "userdata_name";
      if (sortField === "userData.document") sortField = "userdata_document";
      if (sortField === "ag") sortField = "account_account";

      const response = await fetchContas(selectedManager, newPage + 1, newRowsPerPage, sortField, sortOrder, onlyManagerAccounts, chosenbank);
      setTabelaFiltro(response.data || []);
      setTotalItems(response.totalItems || 0);
    } catch (error) {
      handleSnackbar('Erro ao buscar contas do gerente', 'error');
    } finally {
      if (setLoading) setLoading(false);
    }
  };

  const handleManagerChange = (selectedValue) => {
    setSelectedManager(selectedValue);
    setPage(0);
  };

  // Paginação:
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchData(newPage, rowsPerPage, orderBy, order);
  };

  // Linhas por página:
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    fetchData(0, newRowsPerPage, orderBy, order);
  };


  
  // ...
  
  // Função para alternar os bloqueios chamando a API para atualizar a configuração do usuário
  const toggleBloqueio = async (tipo) => {
    let field;
    let newValue;
    // Se o valor 1 indica ação permitida (desbloqueado) e 0 bloqueado,
    // usamos a lógica: se o estado local está bloqueado (true), ao togglar queremos desbloquear (valor 1)
    // e se estiver desbloqueado (false), queremos bloquear (valor 0).
    switch (tipo) {
      case 'criacaoConta':
        field = 'cancreateaccount';
        newValue = statusCriacaoConta ? 1 : 0;
        break;
      case 'saqueGerente':
        field = 'managercanwithdraw';
        newValue = statusSaqueGerente ? 1 : 0;
        break;
      case 'transferencia':
        field = 'cantransfer';
        newValue = statusTransferencia ? 1 : 0;
        break;
      case 'pagamentoQR':
        field = 'canwithdraw';
        newValue = statusPagamentoQR ? 1 : 0;
        break;
      default:
        return;
    }
  
    try {
      // Chama a função de API passando o user.id (do prop) e o campo a ser atualizado.
      const result = await Funcoes.updateUserConfigStatus(selectedManagerMovId, { [field]: newValue });
      if (result.sucesso) {
        // Se a API atualizar com sucesso, atualizamos o estado local invertendo o valor.
        switch (tipo) {
          case 'criacaoConta':
            setStatusCriacaoConta((prev) => !prev);
            break;
          case 'saqueGerente':
            setStatusSaqueGerente((prev) => !prev);
            break;
          case 'transferencia':
            setStatusTransferencia((prev) => !prev);
            break;
          case 'pagamentoQR':
            setStatusPagamentoQR((prev) => !prev);
            break;
          default:
            break;
        }
      } else {
        console.error('Erro ao atualizar configuração: ', result.message);
      }
    } catch (error) {
      console.error('Erro ao chamar updateUserConfigStatus:', error);
    }
  };
  

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Consultar Gerentes</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {/* Seletor de Gerentes */}
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel id="manager-select-label">Selecione um Gerente</InputLabel>
              <Select
                labelId="manager-select-label"
                value={selectedManager}
                onChange={(e) => handleManagerChange(e.target.value)}
                fullWidth
                label="Selecione um Gerente"
              >
                <MenuItem value={0}>TODOS</MenuItem>
                {managers.map((item) => (
                  <MenuItem key={item.idAccount} value={item.managerId}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <span>{item.managerName.toUpperCase()}</span>
                      <span>{`R$: ${Formatar.converterCentavosParaReais(item.balance)}`}</span>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Exibe os botões somente se um gerente estiver selecionado */}
          {selectedManager !== 0 && (
            <Grid item xs={12} sx={{ mt: 3 }}>
              <Grid container spacing={2} sx={{ mb: 3 }}>
                {[
                  { tipo: 'criacaoConta', label: 'Criação de Conta', status: statusCriacaoConta },
                  { tipo: 'saqueGerente', label: 'Saque Gerente', status: statusSaqueGerente },
                  { tipo: 'transferencia', label: 'Transferência para Conta Filha', status: statusTransferencia },
                /*   { tipo: 'pagamentoQR', label: 'Pagamento QR Code (Filhas)', status: statusPagamentoQR } */
                ].map(({ tipo, label, status }) => (
                  <Grid item xs={12} sm={6} md={4} key={tipo}>
                    <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
                      <Typography variant="subtitle1">{label}</Typography>
                      <Typography variant="body2" sx={{ color: status ? 'red' : 'green', fontWeight: 'bold' }}>
                        {status ? 'Bloqueado' : 'Desbloqueado'}
                      </Typography>
                      <Button
                        fullWidth
                        variant="contained"
                        color={status ? 'success' : 'error'}
                        onClick={() => toggleBloqueio(tipo)}
                        sx={{ mt: 1 }}
                      >
                        {status ? 'Desbloquear' : 'Bloquear'}
                      </Button>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
              {/* A seção de contas e paginação segue abaixo */}
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID Conta</TableCell>
                      <TableCell>Nome</TableCell>
                      <TableCell>Documento</TableCell>
                      <TableCell>Saldo</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tabelaFiltro.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          {loading ? <CircularProgress size={24} /> : 'Nenhum dado encontrado.'}
                        </TableCell>
                      </TableRow>
                    ) : (
                      tabelaFiltro.map((conta) => {
                        let saldo = 0;
                        try {
                          const multipleBalance = Array.isArray(conta?.configData?.multiplebalance)
                            ? conta.configData.multiplebalance
                            : JSON.parse(conta?.configData?.multiplebalance || '[]');
                          const balanceData = multipleBalance.find(item => item.bank === chosenbank);
                          saldo = balanceData?.balance || 0;
                        } catch (error) {
                          console.error('Erro ao processar saldo:', error);
                        }
                        return (
                          <TableRow key={conta.id}>
                            <TableCell>{conta.id}</TableCell>
                            <TableCell>{conta.userData.name}</TableCell>
                            <TableCell>{Formatar.cpf_mask(conta.userData.document)}</TableCell>
                            <TableCell>{Formatar.converterCentavosParaReais(saldo)}</TableCell>
                          </TableRow>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {tabelaFiltro.length > 0 && (
                <Grid item xs={12}>
                  <TablePagination
                    component="div"
                    count={totalItems}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[10, 25, 50]}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default ConsultarGerentes;
