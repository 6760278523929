import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    TableSortLabel,
    Button,
    Box,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Snackbar,
    Alert,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    IconButton,
    InputAdornment
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { columns } from './utils/constants';
import * as Formatar from './utils/format';
import moment from 'moment';
import * as Funcoes from '../../functions/functions';
import Compressor from 'compressorjs';
import RefreshIcon from '@mui/icons-material/Refresh';
import TarifaDialog from './utils/components/TarifaDialog';
import IsencaoDialog from './utils/components/IsencaoDialog';

function getNestedValue(obj, path) {
    return path.split('.').reduce((value, key) => (value ? value[key] : null), obj);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const calculateRemainingTime = (creationTime, chosenbank) => {
    let endTime;

    // Define o tempo final com base no banco escolhido
    if (chosenbank === 'qesh') {
        endTime = moment(creationTime).add(3, 'hours').add(40, 'minutes');
    } else if (chosenbank === 'bmb') {
        endTime = moment(creationTime).add(55, 'minutes');
    } else {
        throw new Error(`Banco escolhido não suportado: ${chosenbank}`);
    }

    const now = moment();
    const duration = moment.duration(endTime.diff(now));
    const totalSeconds = duration.asSeconds();

    if (totalSeconds <= 0) {
        return {
            hours: 0,
            minutes: 0,
            seconds: 0,
            totalSeconds: 0,
        };
    }

    return {
        hours: Math.floor(duration.asHours()),
        minutes: duration.minutes(),
        seconds: duration.seconds(),
        totalSeconds,
    };
};


const CountdownTimer = ({ id, creationTime, onTimeEnd, pixKeyId, onPixKeyDelete, chosenbank }) => {
    const [timeLeft, setTimeLeft] = useState(calculateRemainingTime(creationTime, chosenbank));
    const [hasEnded, setHasEnded] = useState(false);

    useEffect(() => {
        const timerId = setInterval(() => {
            const newTimeLeft = calculateRemainingTime(creationTime, chosenbank);

            if (newTimeLeft.totalSeconds <= 0 && !hasEnded) {
                clearInterval(timerId);
                setTimeLeft({
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                    totalSeconds: 0,
                });
                setHasEnded(true);
                if (onTimeEnd) onTimeEnd();
                if (onPixKeyDelete) onPixKeyDelete(pixKeyId, id, false); // Exclui a chave Pix
            } else {
                setTimeLeft(newTimeLeft);
            }
        }, 1000);

        return () => clearInterval(timerId);
    }, [creationTime, onTimeEnd, hasEnded, onPixKeyDelete, pixKeyId, chosenbank]);

    return <span>{`${timeLeft.hours}:${timeLeft.minutes}:${timeLeft.seconds}`}</span>;
};


function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
};

function descendingComparator(a, b, orderBy) {
    const valueA = getNestedValue(a, orderBy);
    const valueB = getNestedValue(b, orderBy);
    if (valueB < valueA) {
        return -1;
    }
    if (valueB > valueA) {
        return 1;
    }
    return 0;
};

const ChaveTexto = {
    0: 'CPF',
    1: 'CNPJ',
    2: 'TELEFONE',
    3: 'EMAIL',
    4: 'EVP'
};

const DataTable = ({ count, state, order, orderBy, rowsPerPage, page, onSort, onChangePage, onChangeRowsPerPage, fetchcheckAuthentication, user, setState }) => {
    const [activatedKeys, setActivatedKeys] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogevp, setOpenDialogevp] = useState(false);
    const [pixCode, setPixCode] = useState('');
    const [openExtratoDialog, setOpenExtratoDialog] = useState(false);
    const [selectedExtrato, setSelectedExtrato] = useState([]);
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
    const [openMasterDialog, setOpenMasterDialog] = useState(false);
    const [openIsencaoDialog, setOpenIsencaoDialog] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const [tipoChavePix, setTipoChavePix] = useState('');
    const [chavePix, setChavePix] = useState('');
    const [manutencaoLoad, setManutencaoLoad] = useState(false);
    const [novaSenha, setNovaSenha] = useState('');
    const [novoEmail, setNovoEmail] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [selectedUserIsencao, setSelectedUserIsencao] = useState({});
    const [casadeaposta, setcasadeaposta] = useState('');
    const [login, setlogin] = useState('');
    const [password, setpassword] = useState('');
    const [motivo, setmotivo] = useState('');
    const [openTarifaDialog, setOpenTarifaDialog] = useState(false);
    const [dadosAcao, setDadosAcao] = useState([]);
    const [descricao, setDescricao] = useState('');
    const [tipo_de_cobranca, setTipoCobranca] = useState('');
    const [valor, setValor] = useState('');
    const [childId, setChildId] = useState('');
    const [kycConta, setKycConta] = useState({
        frontBase64: null,
        backBase64: null,
        face1Base64: null,
        face2Base64: null,
        docFaceBase64: null,
    });
    const [loadingKYC, setLoadingKYC] = useState(false);
    const [kycStatus, setKycStatus] = useState('');
    const [tariff, setTariff] = useState([]);
    const [chosenbank, setChosenbank] = useState(localStorage.getItem('chosenbank'));
    const [selectedTypeKey, setSelectedTypeKey] = useState(null);
    const [novoCpfComissao, setnovoCpfComissao] = useState('');
    const [editandoBeneficiario, setEditandoBeneficiario] = useState(false);

    useEffect(() => {
        async function fetchDatacheck() {
            await getTariffUser();
        }
        fetchDatacheck();
    }, []);


    const getTariffUser = async () => {
        const tariff = await Funcoes.getTariffUser(user.id);
        //console.log(tariff);
        setTariff(tariff);
    };

    const handleActivate = async (row) => {
        let id = row.id;
        setChildId(row.id);
        try {
            const userConfig = state.tabelaFiltro.find(row => row.id == id)?.configData;
            const chaveCpfInativa = userConfig?.keypix && JSON.parse(userConfig.keypix).some(
                chave => chave.typekey == 0 && chave.status != 1
            );

            if (user.accountData[0]?.profile === 'master' || user.accountData[0]?.profile === 'gerenteMasterEVP') {
                // Abre o diálogo para o usuário escolher o tipo de chave
                setOpenDialogevp(true);

                // Aguarda a escolha do usuário
                /*       const escolhaTypekey = await new Promise((resolve) => {
                          const interval = setInterval(() => {
                              if (selectedTypeKey !== null) {
                                  clearInterval(interval);
                                  resolve(selectedTypeKey);
                                  //setSelectedTypeKey(null); // Reseta o valor após a escolha
                              }
                          }, 100);
                      });
      
                      //console.log(escolhaTypekey)
      
                      if (escolhaTypekey !== null) {
                          // Cria a chave Pix com o tipo escolhido
                          await handleCreatePixKey({ typekey: escolhaTypekey, userId: id, chosenbank: chosenbank }, true);
                          //setSnackbar({ open: true, message: 'Chave Pix criada com sucesso.', severity: 'success' });
                      } */
            } else {
                // Comportamento padrão para outros perfis
                if (!chaveCpfInativa) {
                    // Chave CPF está inativa, criar uma nova chave Pix CPF
                    await handleCreatePixKey({ typekey: 0, userId: id, chosenbank: chosenbank }, true);
                    setSnackbar({ open: true, message: 'Chave Pix criada com sucesso.', severity: 'success' });
                }
            }
        } catch (error) {
            console.error("Erro ao ativar a chave Pix:", error);
            setSnackbar({ open: true, message: 'Erro ao ativar a chave Pix.', severity: 'error' });
        }
    };




    /*     const handleActivate = async (row) => {
    
            let id = row.id;
            try {
                const userConfig = state.tabelaFiltro.find(row => row.id == id)?.configData;
                const chaveCpfInativa = userConfig?.keypix && JSON.parse(userConfig.keypix).some(
                    chave => chave.typekey == 0 && chave.status != 1
                );
    
                if (!chaveCpfInativa) {
                    // Chave CPF está inativa, criar uma nova chave Pix CPF
                    await handleCreatePixKey({ typekey: 0, userId: id, chosenbank: chosenbank }, true); // Passa true para fromActivation
                }
    
            } catch (error) {
                //console.error("Erro ao ativar a chave Pix:", error);
                setSnackbar({ open: true, message: 'Erro ao ativar a chave Pix.', severity: 'error' });
            }
        }; */

    const handleOpenDialog = (row) => {
        setChildId(row.id);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setPixCode(''); // Limpar o campo ao fechar
    };

    const handleOpenMasterDialog = async (id, updateData = false) => {
        setLoading(true); // Inicia o loading
        try {
            const response = await Funcoes.getUserDetail(id);
            //console.log(response);
            setUserDetails(response);

            if (updateData) {
                // Verifica se 'data' é um array antes de tentar usar o .map()
                setState(prevState => {
                    const prevData = Array.isArray(prevState.tabelaFiltro) ? prevState.tabelaFiltro : [];

                    return {
                        ...prevState,
                        tabelaFiltro: prevData.map(item =>
                            item.id == id ? { ...item, ...response } : item
                        )
                    };
                });

            }

            else {
                setOpenMasterDialog(true);
            }
        } catch (error) {
            //console.error('Erro ao obter detalhes do usuário:', error);
            setSnackbar(prevState => ({ ...prevState, open: true, message: 'Erro ao carregar detalhes do usuário.', severity: 'error' }));
        } finally {
            setLoading(false); // Termina o loading
        }
    };

    const handleCloseMasterDialog = () => {
        setOpenMasterDialog(false);
        setKycStatus("");
        setLoadingKYC(false);
    };

    const handleCloseIsencaoDialog = () => {
        setOpenIsencaoDialog(false);
        setSelectedUserIsencao({});
        setcasadeaposta('');
        setlogin('');
        setpassword('');
        setmotivo('');
    };

    const handleOpenExtratoDialog = async (row) => {
        const accountData = row.accountData?.[0];
        const account = accountData?.id;

        if (!account) {
            //console.error('Conta não encontrada para o usuário:', row.userData.name);
            setSnackbar({ open: true, message: 'Conta não encontrada.', severity: 'error' });
            return;
        }

        //console.log('Buscando extrato para a conta:', account);
        setLoading(true);
        try {
            const fromDate = moment().subtract(30, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss');
            const toDate = moment().subtract('day').endOf('day').format('YYYY-MM-DD HH:mm:ss');


            //console.log('Extrato toDate:', toDate);

            const extrato = await Funcoes.fetchExtrato(account, fromDate, toDate, 0, 100);
            //console.log('Extrato encontrado:', extrato);
            setSelectedExtrato(extrato.data);
            setSnackbar({ open: true, message: 'Extrato carregado com sucesso!', severity: 'success' });
        } catch (error) {
            //console.error('Erro ao buscar extrato:', error);
            setSnackbar({ open: true, message: 'Erro ao buscar extrato.', severity: 'error' });
        } finally {
            setLoading(false);
        }
        setOpenExtratoDialog(true);
    };

    const handleCloseExtratoDialog = () => {
        setOpenExtratoDialog(false);
        setSelectedExtrato([]);
    };

    const handleSaque = async (row) => {
        setLoading(true);

        const accountData = row.accountData?.[0];
        const account = accountData?.userId;
        const value = row?.configData?.balance;

        if (!account || !value) {
            //console.error('Informações da conta ou valor inválidos.');
            setSnackbar({ open: true, message: 'Informações da conta ou valor inválidos.', severity: 'error' });
            setLoading(false);
            return;
        }

        try {
            const payload = {
                payerUserId: account,
                managerUserId: user.id,
                adminUserId: 1,
                value: value,
                tariffkey: 'tarifa_saque_filha',
                operatorid: user.accountData[0]?.operatorid || '',
                chosenbank
            };
            //console.log(payload);
            const response = await Funcoes.createSplitExternalMovement(payload);
            //console.log(response);

            if (response.sucesso) {
                //consultarMov({ id: response.data.endToEnd, desc: 'Saque realizado com sucesso!', user: account });

                await fetchcheckAuthentication();
                await handleOpenMasterDialog(account, true); // Recarrega as informações do usuário
                setSnackbar({ open: true, message: 'Saque realizado com sucesso!', severity: 'success' });
                setChildId('');
                setOpenDialog(false);
                setPixCode(''); // Limpar o campo ao fechar 
                setLoading(false); // Finaliza o estado de carregamento
            }



        } catch (error) {
            //console.error('Erro ao realizar o saque:', error);
            setSnackbar({ open: true, message: 'Erro ao realizar o saque.', severity: 'error' });
            setLoading(false);
        }
    };

    const handleCreatePixKey = async ({ typekey, userId, chosenbank }, fromActivation = false) => {
        setLoading(true);
        try {
            const payload = { userId, typekey, chosenbank };
            const response = await Funcoes.createKeyPix(payload);
            //console.log(response);

            // Tratamento de erro específico para mensagem de "Await 2 minutes after account creation"
            if (response?.sucesso == false) {
                const errorMsg = response?.details?.data?.mensagem || response?.details?.data?.error || response?.message;

                if (errorMsg && errorMsg.includes("Await 2 minutes after account creation")) {
                    setSnackbar({
                        open: true,
                        message: "A chave Pix só pode ser ativada após 2 minutos da criação da conta.",
                        severity: "warning",
                    });
                    return;
                }

                // Se houver outro erro, utiliza a mensagem retornada ou uma mensagem padrão
                setSnackbar({
                    open: true,
                    message: "A chave Pix não pode ser ativada.",
                    severity: "error",
                });
                return;
            }

            // Caso sucesso, exibe mensagem com o tipo de chave correspondente
            const TIPO_CHAVE_TEXTO = {
                0: 'CPF',
                1: 'CNPJ',
                2: 'PHONE',
                3: 'EMAIL',
                4: 'EVP'
            };

            setSnackbar({
                open: true,
                message: `Chave Pix do tipo ${TIPO_CHAVE_TEXTO[typekey]} criada com sucesso!`,
                severity: 'success',
            });

            if (fromActivation) {
                // Ativa o timer após a atualização dos dados e a criação da chave Pix
                const newTime = moment().toISOString();
                setTimeout(() => {
                    setActivatedKeys((prev) => ({ ...prev, [userId]: newTime }));
                }, 4000);
            }

            await handleOpenMasterDialog(userId, true); // Recarrega as informações do usuário

        } catch (error) {
            setSnackbar({
                open: true,
                message: 'Erro ao criar chave Pix.',
                severity: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    const handleDeletePixKey = async (chavePix, id, mostrar = true) => {
        setLoading(true);
        try {
            const response = await Funcoes.deleteKeyPix({ chavePix, chosenbank });
            //console.log(response);
            if (mostrar) { setSnackbar({ open: true, message: `Chave Pix ${chavePix} deletada com sucesso!`, severity: 'success' }); }

            // Aguarda a exclusão ser concluída antes de atualizar o usuário
            await handleOpenMasterDialog(id, true);

            // Atualiza o estado `activatedKeys` para reativar o botão "Ativar"
            setActivatedKeys(prevKeys => {
                const updatedKeys = { ...prevKeys };
                delete updatedKeys[id]; // Remove o ID do usuário das chaves ativas
                return updatedKeys;
            });

        } catch (error) {
            //console.log(error);
            setSnackbar({ open: true, message: 'Erro ao deletar chave Pix.', severity: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ open: false, message: '', severity: '' });
    };

    const handleSelectKey = (event) => {
        setTipoChavePix(event.target.value);
    };

    const handleInputChange = (event) => {
        setChavePix(event.target.value);
    };


    const handleInputChangeEmail = (event) => {
        setNovoEmail(event.target.value);
    };

    const criarChavePixPersonalizada = async () => {
        // Verificação dos campos obrigatórios
        if (tipoChavePix === '' || chavePix.trim() === '') {
            setSnackbar({ open: true, message: 'Por favor, preencha todos os campos.', severity: 'warning' });
            return;
        }

        setManutencaoLoad(true);
        try {
            await Funcoes.createKeyPixDirect({
                tipoChave: tipoChavePix,
                chave: chavePix,
                userId: userDetails.id
            });
            setSnackbar({ open: true, message: 'Chave Pix criada com sucesso!', severity: 'success' });
            await handleOpenMasterDialog(userDetails.id); // Recarrega as informações do usuário

            // Limpar as variáveis após o sucesso
            setTipoChavePix('');
            setChavePix('');
        } catch (error) {
            //console.error('Erro ao criar chave Pix:', error);
            setSnackbar({ open: true, message: 'Erro ao criar chave Pix.', severity: 'error' });
        } finally {
            setManutencaoLoad(false);
        }
    };

    const handleToggleAccountStatus = async (account) => {
        const newStatus = account.status == 1 ? 2 : 1;

        try {
            // Chame a função de atualização de conta com o novo status
            await Funcoes.updateAccountStatus(account.userId, newStatus);
            setSnackbar({ open: true, message: `Conta ${newStatus == 1 ? 'Ativada' : 'Bloqueada'} com sucesso!`, severity: 'success' });

            // Recarregar as informações do usuário para refletir o novo status
            await handleOpenMasterDialog(account.userId);
        } catch (error) {
            //console.error('Erro ao alternar o status da conta:', error);
            setSnackbar({ open: true, message: 'Erro ao alterar o status da conta.', severity: 'error' });
        }
    };

    const handleToggleCanTransfer = async (account) => {
        //console.log(account);
        const newStatus = account.cantransfer == 1 ? 0 : 1;

        try {
            // Chame a função de atualização de conta com o novo status
            await Funcoes.updateUserCanTransfer(account.userId);
            setSnackbar({ open: true, message: `Pagar QRCode ${newStatus == 1 ? 'desbloqueado' : 'bloqueado'} com sucesso!`, severity: 'success' });

            // Recarregar as informações do usuário para refletir o novo status
            await handleOpenMasterDialog(account.userId, true);
        } catch (error) {
            //console.error('Erro ao alternar o status da conta:', error);
            setSnackbar({ open: true, message: 'Erro ao alterar o status do pagar qrcode.', severity: 'error' });
        }
    };

    const handleUpdateBalance = async ({ account, userId, chosenbank, mostrar = true }) => {
        try {
            // Chama a função de atualização de saldo com os detalhes da conta
            await Funcoes.updateBalanceAccount(account, chosenbank);
            setSnackbar({ open: true, message: 'Saldo atualizado com sucesso!', severity: 'success' });
            if (mostrar) {
                // Recarrega as informações do usuário para refletir o novo saldo
                await handleOpenMasterDialog(userId);
            }

        } catch (error) {
            //console.error('Erro ao atualizar saldo da conta:', error);
            setSnackbar({ open: true, message: 'Erro ao atualizar o saldo da conta.', severity: 'error' });
        }
    };


    const handleGerarOperator = async (name, account, XAccountId, id) => {
        try {
            // Chama a função de criação da conta operador com os detalhes fornecidos
            await Funcoes.GerarOperator(name, account, XAccountId);
            setSnackbar({ open: true, message: 'Conta Operador criada com sucesso!', severity: 'success' });

            // Recarrega as informações do usuário para refletir a nova conta
            await handleOpenMasterDialog(id);
        } catch (error) {
            //console.error('Erro ao criar conta operador:', error);
            setSnackbar({ open: true, message: 'Erro ao criar conta operador.', severity: 'error' });
        }
    };

    const formatTelefone = (telefone) => {
        if (!telefone) return '';
        return telefone.replace(/^(\d{2})(\d{5})(\d{4}).*/, '($1) $2-$3');
    };

    const formatDocumento = (documento) => {
        if (!documento) return '';
        if (documento.length === 11) {
            // CPF
            return documento.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        } else if (documento.length === 14) {
            // CNPJ
            return documento.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        }
        return documento;
    };

    const formatDataNascimento = (data) => {
        if (!data) return '';
        const date = new Date(data);
        return date.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
    };

    const formatSexo = (sexo) => {
        if (!sexo) return '';
        return sexo === 'M' ? 'Masculino' : 'Feminino';
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        // Verifica se o valor tem apenas números e se tem no máximo 6 caracteres
        if (/^\d{0,6}$/.test(value)) {
            setNovaSenha(value);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleUpdatePassword = async (userId, password) => {
        setLoading(true);
        try {
            const response = await Funcoes.updateUserPassword(userId, password);
            setSnackbar({ open: true, message: 'Senha atualizada com sucesso!', severity: 'success' });
            setNovaSenha('');  // Limpa o campo após a atualização
        } catch (error) {
            //console.error('Erro ao atualizar a senha:', error);
            setSnackbar({ open: true, message: 'Erro ao atualizar a senha.', severity: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleUpdateEmail = async (userId, email) => {
        if (!validateEmail(email)) {
            setSnackbar({ open: true, message: 'Por favor, insira um e-mail válido.', severity: 'warning' });
            return;
        }

        setLoading(true);
        try {
            const response = await Funcoes.updateUserEmail(userId, email);
            setSnackbar({ open: true, message: 'E-mail atualizado com sucesso!', severity: 'success' });
            setNovoEmail('');  // Limpa o campo após a atualização
            await handleOpenMasterDialog(userId);
        } catch (error) {
            setSnackbar({ open: true, message: 'Erro ao atualizar o e-mail.', severity: 'error' });
        } finally {
            setLoading(false);
        }
    };


    const handleInputChangeCpf = (event) => {
        let rawValue = event.target.value.replace(/\D/g, ''); // Remove tudo que não for número
        let formattedCpf = Formatar.cpf_mask(rawValue); // Aplica a máscara do CPF
        setnovoCpfComissao(formattedCpf); // Atualiza o estado com o CPF formatado
    };

    const handleUpdateCpf = async (userId, cpf) => {
        if (!Formatar.validar_cpf(cpf)) { // Supondo que você tenha uma função de validação de CPF
            setSnackbar({ open: true, message: 'Por favor, insira um CPF válido.', severity: 'warning' });
            return;
        }

        setLoading(true);
        try {
            const response = await Funcoes.updateUserBeneficiary(userId, cpf); // Atualiza o beneficiário no backend
            setSnackbar({ open: true, message: 'Beneficiário cadastrado com sucesso!', severity: 'success' });
            setnovoCpfComissao(''); // Limpa o campo após a atualização
            setEditandoBeneficiario(false); // Fecha o modo de edição se estiver ativo
            await handleOpenMasterDialog(userId); // Atualiza os dados na interface
        } catch (error) {
            setSnackbar({ open: true, message: 'Erro ao cadastrar o beneficiário.', severity: 'error' });
        } finally {
            setLoading(false);
        }
    };


    const handleSolicitarIsencaoDialog = async () => {

        const tarifaItem = tariff.find((item) => item.tariffkey === "tarifa_criar_pedido_isencao").value;
        const multipleBalance = Array.isArray(user?.configData?.multiplebalance)
            ? user.configData.multiplebalance
            : JSON.parse(user?.configData?.multiplebalance || '[]');

        const balanceData = multipleBalance.find(item => item.bank === chosenbank);
        const balance = balanceData?.balance || 0;

        //console.log(tarifaItem);
        //console.log(balance);

        if (balance < tarifaItem) {
            setSnackbar({
                open: true,
                message: 'Saldo insuficiente',
                severity: 'error' // Define a severidade como erro
            });
            return;
        }

        /*     // Verificando se todos os campos estão preenchidos
            if (!casadeaposta || !login || !password || !motivo) {
                // Atualizar o estado da Snackbar para mostrar a mensagem de erro
                setSnackbar({
                    open: true,
                    message: 'Por favor, preencha todos os campos obrigatórios.',
                    severity: 'error' // Define a severidade como erro
                });
                return; // Para a execução se os campos não estiverem preenchidos
            } */


        const response = await Funcoes.getExemptionById(selectedUserIsencao.id, 1);
        //console.log(response);

        if (response.sucesso) {
            // Atualizar o estado da Snackbar para mostrar a mensagem de erro
            setSnackbar({
                open: true,
                message: 'Já existe um pedido de Isenção para está conta',
                severity: 'error' // Define a severidade como erro
            });
            return; // Para a execução se os campos não estiverem preenchidos
        }


        try {
            const payload = {
                bettinghouse: casadeaposta,
                login: login,
                password: password,
                reason: motivo,
                value: 0,
                originaccount: selectedUserIsencao.id,
                status: 1,
                managerId: user.id,
                tariffkey: "tarifa_criar_pedido_isencao",
                demand: user.accountData[0]?.profile === 'master' ? 0 : 1,
                chosenbank

            };
            //console.log(payload);
            const response = await Funcoes.createExemptionAccount(payload);
            //console.log('Isenção criada com sucesso:', response);
            // Se todos os campos estiverem preenchidos, mostrar mensagem de sucesso
            handleCloseIsencaoDialog()
            setSnackbar({
                open: true,
                message: 'Isenção criada com sucesso',
                severity: 'success' // Define a severidade como sucesso
            });

        } catch (error) {
            //console.error('Erro ao solicitar a isenção:', error);
            setSnackbar({ open: true, message: error.message, severity: 'error' });
        }


    };

    const handleChange = (event) => {
        const inputValue = event.target.value;
        const regex = /^[0-9.,]*$/; // Permitir apenas números, pontos e vírgulas

        if (regex.test(inputValue)) {
            setValor(inputValue);
        }
    };

    const handleChangeTipo = (event) => {
        const inputValue = event.target.value;
        setTipoCobranca(inputValue);
    };

    const fecharModalTariaPadrao = () => {
        setOpenTarifaDialog(false);
    };

    const alterarTarifa = async () => {
        // Ativa o estado de carregamento
        setOpenTarifaDialog(true);
        const originaccount = userDetails.id;
        try {
            // Faz a chamada para a função de alteração da tarifa, passando os dados dos states
            const response = await Funcoes.alterarTarifaPadrao(
                dadosAcao.tariffkey,  // Pegando o chaveTarifa de dadosAcao
                descricao,
                valor,
                tipo_de_cobranca,
                dadosAcao,
                originaccount

            );

            //console.log(response);
            //console.log('Tarifa alterada com sucesso', 'success');

            // Desativa o estado de carregamento e fecha o diálogo
            setOpenTarifaDialog(false);
            setDadosAcao([]);
            setDescricao('');
            setTipoCobranca('');
            setValor('');

            setTimeout(async () => {
                await handleOpenMasterDialog(userDetails.id, true); // Recarrega as informações do usuário
            }, 2000);

        } catch (error) {
            //console.error('Erro na alteração da tarifa', error);

            // Desativa o estado de carregamento e fecha o diálogo em caso de erro
            setOpenTarifaDialog(false);
        }
    };

    const compressImage = (file) => {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 0.3, // Reduzir a qualidade ainda mais
                width: file.width * 0.8, // Reduzir a largura em 80% do original
                height: file.height * 0.8, // Reduzir a altura em 80% do original
                success(result) {
                    resolve(result);
                },
                error(err) {
                    reject(err);
                },
            });
        });
    };


    const handleImageUpload = async (event, imageType) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const compressedFile = await compressImage(file);
                const reader = new FileReader();
                reader.onloadend = () => {
                    setKycConta(prevState => ({
                        ...prevState,
                        [`${imageType}Base64`]: reader.result.split(',')[1] // Remove o prefixo da base64
                    }));
                };
                reader.readAsDataURL(compressedFile); // Converte o arquivo comprimido para base64
            } catch (error) {
                //console.error('Erro ao comprimir a imagem:', error);
            }
        }
    };

    const handleSendImageUpload = async (Conta) => {
        setLoading(true);

        // Verifica se cada imagem foi carregada e exibe mensagens específicas
        if (!kycConta.frontBase64) {
            setSnackbar({ open: true, message: 'Por favor, carregue a imagem da frente do documento.', severity: 'warning' });
            setLoading(false);
            return;
        }
        if (!kycConta.backBase64) {
            setSnackbar({ open: true, message: 'Por favor, carregue a imagem do verso do documento.', severity: 'warning' });
            setLoading(false);
            return;
        }
        if (!kycConta.face1Base64) {
            setSnackbar({ open: true, message: 'Por favor, carregue a foto frontal da pessoa.', severity: 'warning' });
            setLoading(false);
            return;
        }
        if (!kycConta.face2Base64) {
            setSnackbar({ open: true, message: 'Por favor, carregue a foto lateral da pessoa.', severity: 'warning' });
            setLoading(false);
            return;
        }
        if (!kycConta.docFaceBase64) {
            setSnackbar({ open: true, message: 'Por favor, carregue a imagem do documento com a foto da pessoa.', severity: 'warning' });
            setLoading(false);
            return;
        }

        try {
            const response = await Funcoes.enviarKycDoc(Conta, kycConta, chosenbank);
            setSnackbar({ open: true, message: 'KYC enviado com sucesso!', severity: 'success' });
        } catch (error) {
            //console.error('Erro ao enviar o KYC:', error);
            setSnackbar({ open: true, message: error.message || 'Erro ao enviar o KYC.', severity: 'error' });
        } finally {
            setLoading(false); // Finaliza o indicador de carregamento
        }
    };

    // Função para validar o transactionId
    const isValidTransactionId = (transactionId, initiationType) => {
        const regex = /^[A-Za-z0-9]+$/; // Verifica se contém apenas letras e números
        const length = transactionId.length;

        if (!regex.test(transactionId)) {
            return false; // Se contiver caracteres inválidos, retorna falso
        }

        if (initiationType === 'QRES' && length <= 25) {
            return true; // Valida para QRES (até 25 caracteres)
        }

        if (initiationType === 'QRDN' && length >= 26 && length <= 35) {
            return true; // Valida para QRDN (entre 26 e 35 caracteres)
        }

        return false; // Se não atender às condições, retorna falso
    };

    // Função para gerar um transactionId com base no tipo de Pix
    const generateTransactionId = (initiationType) => {
        let transactionId = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const length = initiationType === 'QRES' ? 25 : getRandomInt(26, 35); // QRES até 25 caracteres, QRDN entre 26-35

        for (let i = 0; i < length; i++) {
            transactionId += characters.charAt(Math.floor(Math.random() * characters.length));
        }

        return transactionId;
    };

    // Função auxiliar para gerar um número aleatório em um intervalo
    const getRandomInt = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const handlePay = async ({ managerId, childId, pixCopiaColaData, chosenbank }) => {
        //console.log('Iniciando pagamento com Pix "Copia e Cola".');

        if (!pixCopiaColaData) {
            console.warn('Pix "Copia e Cola" não fornecido.');
            setSnackbar({ open: true, message: 'Pix "Copia e Cola" não fornecido', severity: 'warning' });
            return;
        }

        setLoading(true); // Inicia o estado de carregamento

        try {
            // Log inicial da consulta do Pix "Copia e Cola"
            //console.log('Consultando detalhes do Pix "Copia e Cola".', { userId: childId, chavePix: pixCopiaColaData, chosenbank });

            const pixCopiaColaResponse = await Funcoes.consultarPix({
                userId: childId,
                chavePix: pixCopiaColaData,
                typekey: 2,
                chosenbank
            });

            //console.log('Resposta da consulta Pix "Copia e Cola":', pixCopiaColaResponse);
            const pixDetails = pixCopiaColaResponse.data;
            const { transactionIdentification, amount, key } = pixDetails;

            if (!key) {
                console.error('Chave Pix não encontrada na consulta "Copia e Cola".');
                setSnackbar({ open: true, message: 'Erro ao consultar Pix "Copia e Cola", chave Pix não encontrada', severity: 'error' });
                setLoading(false);
                return;
            }

            // Log para consulta da chave Pix
            //console.log('Consultando detalhes da chave Pix:', { chavePix: key, userId: childId, chosenbank });
            const pixKeyDetailResponse = await Funcoes.consultarPix({
                userId: childId,
                chavePix: key,
                typekey: 1,
                chosenbank
            });

            //console.log('Resposta da consulta da chave Pix:', pixKeyDetailResponse);
            const pixKeyDetails = pixKeyDetailResponse.data;
            const { ownerName, ownerDocument, ispb, accountBranch, accountNumber, accountType } = pixKeyDetails;

            if (!ownerName || !ownerDocument || !ispb || !accountBranch || !accountNumber || !accountType) {
                console.error('Dados do credor incompletos:', pixKeyDetails);
                setSnackbar({ open: true, message: 'Dados do credor incompletos. Não foi possível realizar o pagamento.', severity: 'error' });
                setLoading(false);
                return;
            }

            //console.log('Montando os dados do credor.');
            const creditorDetails = {
                name: ownerName,
                document: ownerDocument,
                account: {
                    ispb,
                    branch: accountBranch,
                    number: accountNumber,
                    type: accountType
                }
            };

            //console.log('Verificando o tipo de QR Code (estático ou dinâmico).');
            const initiationType = pixDetails.type === "STATIC" ? "QRES" : "QRDN";

            //console.log('Gerando o transactionId.');
            const transactionId = isValidTransactionId(transactionIdentification, initiationType)
                ? transactionIdentification
                : transactionIdentification;

            //console.log('Realizando transferência com Pix.');
            const result = await Funcoes.realizarTransferenciaComPix({
                managerId,
                childId,
                pixKey: {
                    id: pixDetails.key,
                    endToEndId: transactionId,
                    ownerName: creditorDetails.name,
                    ownerDocument: creditorDetails.document,
                    ispb: creditorDetails.account.ispb,
                    accountBranch: creditorDetails.account.branch,
                    accountNumber: creditorDetails.account.number,
                    accountType: creditorDetails.account.type
                },
                pixAmount: amount,
                creditor: creditorDetails,
                initiationType,
                chosenbank
            });

            //console.log('Resultado da transferência e Pix:', result);

            if (result || result.sucesso) {
                //console.log('Transferência e Pix realizados com sucesso.');
                await fetchcheckAuthentication();
                await handleOpenMasterDialog(childId, true);
                setSnackbar({ open: true, message: 'Transferência realizada com sucesso!', severity: 'success' });
                setChildId('');
                setOpenDialog(false);
                setPixCode('');
                setLoading(false);
            } else {
                console.error('Erro ao realizar transferência Pix: resposta inválida.', result);
                await fetchcheckAuthentication();
                await handleOpenMasterDialog(childId, true);
                setSnackbar({ open: true, message: 'Erro ao realizar Transferência Pix', severity: 'error' });
                setLoading(false);
            }
        } catch (error) {
            console.error('Erro no processo de transferência e Pix:', error);
            await fetchcheckAuthentication();
            await handleOpenMasterDialog(childId, true);
            setSnackbar({ open: true, message: 'Erro ao realizar Transferência Pix', severity: 'error' });
            setLoading(false);
        }
    };


    const consultarMov = async ({ id, desc, user }) => {

        const result = await Funcoes.consultarMovimentacao(id);
        //console.log(result);

        if (result == 0 || result?.status != "COMPLETED") {
            setTimeout(() => {
                consultarMov({ id, desc, user });
            }, 3000);

        } else {
            await fetchcheckAuthentication();
            await handleOpenMasterDialog(user, true); // Recarrega as informações do usuário
            setSnackbar({ open: true, message: desc, severity: 'success' });
            setChildId('');
            setOpenDialog(false);
            setPixCode(''); // Limpar o campo ao fechar 
            setLoading(false); // Finaliza o estado de carregamento
        }

    };

    // Função para verificar o status do KYC
    const verificarStatusKYC = async (accountId) => {
        setLoadingKYC(true);
        try {
            const response = await Funcoes.verificarStatusKYC(accountId);
            setKycStatus(response.data.kyc); // Atualiza o status do KYC
        } catch (error) {
            //console.error('Erro ao verificar o status do KYC:', error);
            setKycStatus('Erro ao verificar o status');
        } finally {
            setLoadingKYC(false);
        }
    };

    return (
        <>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="medium" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {columns
                                .filter(column => {
                                    // Verifica se a coluna é "evp" e aplica a lógica de exibição
                                    if (column.id === 'evp') {
                                        return user.accountData[0]?.profile === 'master' || user.accountData[0]?.profile === 'gerenteMasterEVP';
                                    }
                                    return true; // Exibe todas as outras colunas
                                })
                                .map((column, index) => (
                                    <TableCell
                                        key={index}
                                        align="left"
                                        sortDirection={orderBy === column.id ? order : false}
                                    >
                                        <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'asc'}
                                            onClick={() => onSort(column.id)}
                                        >
                                            {column.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            <TableCell align="center">Ação</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {stableSort(state.tabelaFiltro, getComparator(order, orderBy))

                            .map((row, index) => {
                                const areButtonsDisabled = (row) => row.accountData.find((account) => account.chosenbank === chosenbank)?.status == 2 || row.accountData.find((account) => account.chosenbank === chosenbank)?.status == 0;

                                return (
                                    <TableRow
                                        key={index}
                                        className={index % 2 === 0 ? 'even-row' : 'odd-row'}
                                        sx={{
                                            backgroundColor: row.configData?.keypix && JSON.parse(row.configData.keypix).some(chave => chave.typekey == 0 && chave.status == 1)
                                                ? 'lightblue' // Cor azul claro quando o Pix estiver ativo
                                                : 'inherit', // Mantém a cor padrão caso contrário
                                        }}
                                    >
                                        {columns.map((column) => (
                                            <TableCell key={column.id} align="left">
                                                {column.id === 'id' && row.id}
                                                {column.id === 'userData.name' && row.userData.name.toUpperCase()}
                                                {column.id === 'ag' && row.accountData[0].account}
                                                {column.id === 'userData.document' && Formatar.cpf_mask(row.userData.document)}
                                                {column.id === 'evp' && (user.accountData[0]?.profile == 'master' || user.accountData[0]?.profile == 'gerenteMasterEVP') && (
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        {row.configData?.keypix ? (
                                                            JSON.parse(row.configData.keypix).some(chave => chave.typekey == 4) ? (
                                                                JSON.parse(row.configData.keypix).map((chave, index) => {
                                                                    if (chave.typekey == 4) { // Verifica se é uma chave EVP (aleatória)
                                                                        return (
                                                                            <Typography key={index} variant="h6" sx={{ fontSize: '0.8em' }}>
                                                                                {chave.keypix} {/* Exibe a chave EVP */}
                                                                            </Typography>
                                                                        );
                                                                    }
                                                                    return null;
                                                                })
                                                            ) : (
                                                                <Typography variant="h6" sx={{ fontSize: '0.8em' }}>{""}</Typography>
                                                            )
                                                        ) : (
                                                            <Typography variant="h6" sx={{ fontSize: '0.8em' }}>{""}</Typography>
                                                        )}
                                                    </Box>
                                                )}
                                                {column.id === 'userData.telephone' && Formatar.cel_mask(row.userData.telephone)}
                                                {column.id === 'configData.balance' && (
                                                    (() => {
                                                        try {
                                                            // Recupera o saldo baseado no chosenbank

                                                            const multipleBalance = Array.isArray(row?.configData?.multiplebalance)
                                                                ? row.configData.multiplebalance
                                                                : JSON.parse(row?.configData?.multiplebalance || '[]');

                                                            const balanceData = multipleBalance.find(item => item.bank === chosenbank);
                                                            const balance = balanceData?.balance || 0;

                                                            // Converte e exibe o saldo em reais
                                                            //return Formatar.converterCentavosParaReais(balance);

                                                            return (
                                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                                    <span>{Formatar.converterCentavosParaReais(balance)}</span>

                                                                    {/* Ícone de reload abaixo do saldo */}
                                                                    <IconButton
                                                                        size="small"
                                                                        color="primary"
                                                                        onClick={() =>
                                                                            handleUpdateBalance({
                                                                                account: row.accountData.find(
                                                                                    (account) => account.chosenbank === chosenbank
                                                                                )?.paymentaccount,
                                                                                userId: row.accountData[0].userId,
                                                                                chosenbank: chosenbank,
                                                                                mostrar: false
                                                                            })
                                                                        }
                                                                    >
                                                                        <RefreshIcon />
                                                                    </IconButton>
                                                                </div>
                                                            );
                                                        } catch (error) {
                                                            console.error('Erro ao processar saldo:', error);
                                                            return 'Erro ao exibir saldo';
                                                        }
                                                    })()
                                                )}
                                                {column.id === 'keyPix' && (
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        {row.configData?.keypix ? (
                                                            JSON.parse(row.configData.keypix).some(chave => chave.typekey == 0 || chave.typekey == 4) ? (
                                                                JSON.parse(row.configData.keypix).map((chave, index) => {
                                                                    if (chave.typekey == 0 || chave.typekey == 4) { // Verifica se é CPF (typekey === 0) ou Aleatória (typekey === 4)
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                <Typography variant="h6" sx={{ fontSize: '0.8em' }}>
                                                                                    {chave.status == 1 ? "ATIVO" : "INATIVO"}
                                                                                </Typography>
                                                                                {chave.status == 1 && (
                                                                                    <CountdownTimer
                                                                                        key={row.configData.userId}
                                                                                        id={row.configData.userId}
                                                                                        creationTime={chave.createdAt}
                                                                                        onTimeEnd={async () => console.log('chave deletada')}
                                                                                        pixKeyId={chave.keypix} // ID da chave Pix para exclusão
                                                                                        onPixKeyDelete={row.accountData[0]?.profile == 'bet' ? handleDeletePixKey : null} // Função para excluir
                                                                                        chosenbank={chosenbank}
                                                                                    />
                                                                                )}
                                                                            </React.Fragment>
                                                                        );
                                                                    }
                                                                    return null;
                                                                })
                                                            ) : (
                                                                <Typography variant="h6" sx={{ fontSize: '0.8em' }}>INATIVO</Typography>
                                                            )
                                                        ) : (
                                                            <Typography variant="h6" sx={{ fontSize: '0.8em' }}>INATIVO</Typography>
                                                        )}
                                                    </Box>
                                                )}
                                            </TableCell>
                                        ))}

                                        <TableCell align="center" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
                                            {/* Verifica se os botões devem ser desabilitados com base no status */}
                                            {['Ativar', 'Pagar QRCode', 'Extrato', 'Saque', 'Isenção'].map((buttonLabel, index) => (
                                                <Button
                                                    key={index}
                                                    sx={{
                                                        backgroundColor: 'white',
                                                        borderRadius: 5,
                                                        boxShadow: 3,
                                                        padding: 1,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                    }}
                                                    disabled={
                                                        !!areButtonsDisabled(row) ||
                                                        (buttonLabel === 'Ativar' && (
                                                            !!activatedKeys[row.id] ||
                                                            !!(row.configData?.keypix && JSON.parse(row.configData.keypix).some(
                                                                chave => chave.typekey == 0 && chave.status == 1 && chave.keypix.length == 11
                                                            ))
                                                        )) ||
                                                        (buttonLabel === 'Pagar QRCode' && (
                                                            !!(row.configData?.cantransfer === 0)
                                                        )) ||
                                                        (buttonLabel === 'Saque' && !!loading)
                                                    }

                                                    onClick={() => {
                                                        switch (buttonLabel) {
                                                            case 'Ativar':
                                                                handleActivate(row);
                                                                break;
                                                            case 'Pagar QRCode':
                                                                handleOpenDialog(row);
                                                                break;
                                                            case 'Extrato':
                                                                handleOpenExtratoDialog(row);
                                                                break;
                                                            case 'Saque':
                                                                handleSaque(row);
                                                                break;
                                                            case 'Isenção':
                                                                setSelectedUserIsencao(row);
                                                                setTimeout(() => {
                                                                    setOpenIsencaoDialog(true);
                                                                }, 1000);
                                                                break;
                                                            default:
                                                                break;
                                                        }
                                                    }}
                                                >
                                                    {buttonLabel}
                                                </Button>
                                            ))}

                                            {/* Botão "Ação Master", sempre habilitado se o perfil for "master" */}
                                            {user.accountData[0]?.profile === 'master' && (
                                                <Button
                                                    sx={{
                                                        backgroundColor: 'white',
                                                        borderRadius: 5,
                                                        boxShadow: 3,
                                                        padding: 1,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                    }}
                                                    onClick={() => handleOpenMasterDialog(row.id)}
                                                >
                                                    Ação Master
                                                </Button>
                                            )}
                                        </TableCell>

                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>

                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={count}  // O total de registros vem do servidor
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={onChangePage}
                    onRowsPerPageChange={onChangeRowsPerPage}
                />
            </TableContainer>

            {/* Dialog para Inserir código Pix */}
            <Dialog open={openDialog} onClose={handleCloseDialog} sx={{ '& .MuiDialog-paper': { width: '600px', maxWidth: '600px' } }}>
                <DialogTitle>Inserir código Pix</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="pixCode"
                        label="Pix copia e cola"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={pixCode}
                        onChange={(e) => setPixCode(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={handleCloseDialog} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={() => handlePay({ managerId: user.id, childId: childId, pixCopiaColaData: pixCode, chosenbank: chosenbank })} color="primary" variant="contained" disabled={loading || user?.configData?.cancreateaccount === 0} // Desabilita o botão enquanto o loading está ativo
                        >
                            {loading ? (
                                <CircularProgress size={24} color="inherit" /> // Mostra o loading
                            ) : (
                                'Pagar' // Texto padrão do botão
                            )}
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>

            {/* Dialog para visualizar o Extrato */}
            <Dialog open={openExtratoDialog} onClose={handleCloseExtratoDialog} maxWidth="lg" fullWidth>
                <DialogTitle>Extrato de Transações</DialogTitle>
                <DialogContent>
                    {loading ? (
                        <Typography>Carregando extrato...</Typography>
                    ) : (
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="extrato table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Descrição</TableCell>
                                        <TableCell align="center">Valor R$</TableCell>
                                        <TableCell align="center">Data/Hora</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedExtrato.map((item, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{
                                                '&:last-child td, &:last-child th': { border: 0 },
                                                backgroundColor: item.value > 0 ? 'lightgreen' : 'lightcoral',
                                            }}
                                        >
                                            <TableCell align="center">{item.description}</TableCell>
                                            <TableCell align="center">{Formatar.converterCentavosParaReais(item.value)}</TableCell>
                                            <TableCell align="center">{Formatar.formatarDateAnoHoraSegundo(item.createdAt)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseExtratoDialog}>Fechar</Button>
                </DialogActions>
            </Dialog>

            {/* Novo Dialog para perfis "master" */}
            <Dialog open={openMasterDialog} onClose={handleCloseMasterDialog} maxWidth="xl" fullWidth>
                <DialogTitle>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        {/* Texto "Ação Master" alinhado à esquerda */}
                        <Typography variant="h6">Ação Master</Typography>

                        {/* Texto "Banco LQ: {userDetails.chosenbank}" alinhado à direita */}
                        <Typography variant="h6">Banco LQ: {chosenbank.toLocaleUpperCase()}</Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {userDetails ? (
                        <>
                            <Grid container spacing={2} alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
                                {/* Caixa 1: Status da Conta e Botão */}
                                <Grid item xs={12} sm={6}>
                                    <Box display="flex" alignItems="center" justifyContent="flex-start" sx={{ padding: 2 }}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', mr: 2 }}>
                                            Status da Conta: {userDetails.accountData[0]?.status == 1 ? "Ativa" : "Bloqueada"}
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            color={userDetails.accountData[0]?.status == 1 ? "secondary" : "primary"}
                                            onClick={() => handleToggleAccountStatus(userDetails.accountData[0])}
                                            sx={{ minWidth: '140px' }}
                                        >
                                            {userDetails.accountData[0]?.status == 1 ? "Bloquear Conta" : "Ativar Conta"}
                                        </Button>
                                    </Box>
                                </Grid>

                                {/* Caixa 2: Status Pagar QRCode e Botão */}
                                <Grid item xs={12} sm={6}>
                                    <Box display="flex" alignItems="center" justifyContent="flex-end" sx={{ padding: 2 }}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold', mr: 2 }}>
                                            Status pagar QRCode: {userDetails.configData?.cantransfer == 1 ? "Desbloqueado" : "Bloqueado"}
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            color={userDetails.configData?.cantransfer == 1 ? "secondary" : "primary"}
                                            onClick={() => handleToggleCanTransfer(userDetails.configData)}
                                            sx={{ minWidth: '140px' }}
                                        >
                                            {userDetails.configData?.cantransfer == 1 ? "Bloquear QRCode" : "Desbloquear QRCode"}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>



                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Informações Básicas</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Nome:</Typography>
                                                <Typography>{userDetails.userData?.name}</Typography>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Email:</Typography>
                                                <Typography>{userDetails.email}</Typography>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Telefone:</Typography>
                                                <Typography>{formatTelefone(userDetails.userData?.telephone)}</Typography>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Documento:</Typography>
                                                <Typography>{formatDocumento(userDetails.userData?.document)}</Typography>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Data de Nascimento:</Typography>
                                                <Typography>{formatDataNascimento(userDetails.userData?.birth)}</Typography>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Paper elevation={3} sx={{ p: 2, borderRadius: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Box>
                                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Saldo:</Typography>
                                                    <Typography>{
                                                        (() => {
                                                            try {
                                                                // Recupera o saldo baseado no chosenbank

                                                                const multipleBalance = Array.isArray(userDetails?.configData?.multiplebalance)
                                                                    ? userDetails.configData.multiplebalance
                                                                    : JSON.parse(userDetails?.configData?.multiplebalance || '[]');

                                                                const balanceData = multipleBalance.find(item => item.bank === chosenbank);
                                                                const balance = balanceData?.balance || 0;

                                                                // Converte e exibe o saldo em reais
                                                                return Formatar.converterCentavosParaReais(balance);
                                                            } catch (error) {
                                                                console.error('Erro ao processar saldo:', error);
                                                                return 'Erro ao exibir saldo';
                                                            }
                                                        })()
                                                    }</Typography>
                                                </Box>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleUpdateBalance({ account: userDetails.accountData.find((account) => account.chosenbank === chosenbank)?.paymentaccount, userId: userDetails.accountData[0].userId, chosenbank: chosenbank })}
                                                >
                                                    Atualizar Saldo
                                                </Button>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>


                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Dados da Conta</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        {userDetails.accountData
                                            .filter((account) => account.chosenbank === chosenbank) // Filtrar pelas contas com o chosenBank correspondente
                                            .map((account, index) => (
                                                <React.Fragment key={index}>
                                                    <Grid item xs={12} sm={6}>
                                                        <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
                                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Banco:</Typography>
                                                            <Typography>{account.bank}</Typography>
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
                                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Agência:</Typography>
                                                            <Typography>{`${account.agency} - Dígito: ${account.digitagency}`}</Typography>
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
                                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Conta:</Typography>

                                                            {
                                                                account.digitaccount == 0 ? (
                                                                    <Typography>{`${account.account}`}</Typography>
                                                                ) : (
                                                                    <Typography>{`${account.account} - Dígito: ${account.digitaccount}`}</Typography>
                                                                )
                                                            }

                                                        </Paper>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Paper elevation={3} sx={{ p: 2, borderRadius: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <Box>
                                                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Conta Pagamento:</Typography>
                                                                <Typography>{account.paymentaccount}</Typography>
                                                            </Box>

                                                            {chosenbank === "qesh" && <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => handleGerarOperator(userDetails.userData?.name, account.account, account.paymentaccount, userDetails.id)}
                                                            >
                                                                Gerar Conta Operator
                                                            </Button>}
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
                                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Status:</Typography>
                                                            <Typography>{account.status == 1 ? "ATIVA" : "BLOQUEADA"}</Typography>
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
                                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Perfil:</Typography>
                                                            <Typography>{account.profile}</Typography>
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
                                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Gerente ID:</Typography>
                                                            <Typography>{account.managerId}</Typography>
                                                        </Paper>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
                                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Nome do Gerente:</Typography>
                                                            <Typography>{account.managerName}</Typography>
                                                        </Paper>
                                                    </Grid>
                                                </React.Fragment>
                                            ))}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>


                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Configurações da Conta</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {/* Exibir as Chaves Pix */}
                                    <Box sx={{ mt: 4, p: 2, border: '1px solid #ccc', borderRadius: 1 }}>
                                        <Typography variant="h6" sx={{ mt: 2 }}>Chaves Pix:</Typography>

                                        <Grid container spacing={2} sx={{ mt: 1 }}>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="h6">Chaves Ativas (Liquidante)</Typography>
                                                {userDetails.configData?.keypix && JSON.parse(userDetails.configData.keypix)
                                                    .filter(chave => chave.status == 1 && chave.chosenbank == chosenbank)
                                                    .map((chave, index) => (
                                                        <Box
                                                            key={index}
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                                p: 1,
                                                                border: '1px solid #ccc',
                                                                borderRadius: 1,
                                                                mb: 1,
                                                            }}
                                                        >
                                                            <Box>
                                                                <Typography variant="body1">
                                                                    {`Banco LQ: ${chave.chosenbank.toLocaleUpperCase()}`} {/* Exibe o nome do tipo de chave */}
                                                                </Typography>
                                                                <Typography variant="body1">
                                                                    {`Tipo: ${ChaveTexto[chave.typekey]}`} {/* Exibe o nome do tipo de chave */}
                                                                </Typography>
                                                                <Typography variant="body1">
                                                                    {`Chave: ${chave.keypix}`}
                                                                </Typography>
                                                            </Box>
                                                            <Button
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={() => handleDeletePixKey(chave.keypix, userDetails.id)}
                                                                sx={{ ml: 2 }}
                                                            >
                                                                Deletar
                                                            </Button>
                                                        </Box>
                                                    ))}
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="h6">Chaves de Saída (Gerente)</Typography>
                                                {userDetails.configData?.keypix && JSON.parse(userDetails.configData.keypix)
                                                    .filter(chave => chave.status == 2)
                                                    .map((chave, index) => (
                                                        <Box
                                                            key={index}
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                                p: 1,
                                                                border: '1px solid #ccc',
                                                                borderRadius: 1,
                                                                mb: 1,
                                                            }}
                                                        >
                                                            <Box>
                                                                <Typography variant="body1">
                                                                    {"Chave Pix de Saída"}
                                                                </Typography>
                                                                <Typography variant="body1">
                                                                    {`Tipo: ${ChaveTexto[chave.typekey]}`} {/* Exibe o nome do tipo de chave */}
                                                                </Typography>
                                                                <Typography variant="body1">
                                                                    {`Chave: ${chave.keypix}`}
                                                                </Typography>
                                                            </Box>
                                                            <Button
                                                                variant="contained"
                                                                color="secondary"
                                                                onClick={() => handleDeletePixKey(chave.keypix, userDetails.id)}
                                                                sx={{ ml: 2 }}
                                                            >
                                                                Deletar
                                                            </Button>
                                                        </Box>
                                                    ))}
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    {/* Botões para criar novas chaves Pix */}
                                    <Box sx={{ mt: 4, p: 2, border: '1px solid #ccc', borderRadius: 1 }}>
                                        <Typography variant="h6" sx={{ mt: 3 }}>Criar Chave Pix da Conta:</Typography>
                                        <Grid container spacing={2} sx={{ mt: 2 }}>
                                            {/*  <Grid item xs={6}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleCreatePixKey({ typekey: 2, userId: userDetails.id })}
                                                >
                                                    Criar Chave Pix por Telefone
                                                </Button>
                                            </Grid> */}
                                            <Grid item xs={6}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleCreatePixKey({ typekey: 0, userId: userDetails.id, chosenbank: chosenbank })}
                                                >
                                                    Criar Chave Pix por CPF/CNPJ
                                                </Button>
                                            </Grid>
                                            {/*    <Grid item xs={6}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleCreatePixKey({ typekey: 3, userId: userDetails.id })}
                                                >
                                                    Criar Chave Pix por E-mail
                                                </Button>
                                            </Grid> */}
                                            <Grid item xs={6}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleCreatePixKey({ typekey: 4, userId: userDetails.id, chosenbank: chosenbank })}
                                                >
                                                    Criar Chave Pix aleatória
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    {/* Novo Box para criar a chave Pix personalizada */}
                                    <Box sx={{ mt: 4, p: 2, border: '1px solid #ccc', borderRadius: 1 }}>
                                        <Typography variant="h6">{"Criar chave Pix de Saida: "}</Typography>
                                        <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                                            <InputLabel id="select-tipo-chave-label">Tipo de chave</InputLabel>
                                            <Select
                                                labelId="select-tipo-chave-label"
                                                id="select-tipo-chave"
                                                value={tipoChavePix}
                                                onChange={handleSelectKey}
                                                label="Tipo de chave"
                                            >
                                                <MenuItem value="">
                                                    <em>Escolha</em>
                                                </MenuItem>
                                                <MenuItem value={0}>CPF</MenuItem>
                                                <MenuItem value={1}>CNPJ</MenuItem>
                                                <MenuItem value={2}>TELEFONE</MenuItem>
                                                <MenuItem value={3}>E-MAIL</MenuItem>
                                                <MenuItem value={4}>ALEATÓRIA</MenuItem>
                                            </Select>
                                        </FormControl>

                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            label="Chave Pix"
                                            value={chavePix}
                                            onChange={handleInputChange}
                                            sx={{ mt: 2 }}
                                        />

                                        {manutencaoLoad ? (
                                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                                <CircularProgress />
                                            </Box>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                onClick={criarChavePixPersonalizada}
                                                sx={{ mt: 2 }}
                                            >
                                                Criar Chave Pix
                                            </Button>
                                        )}
                                    </Box>

                                    {/* Novo Box para alterar a senha */}
                                    <Box sx={{ mt: 4, p: 2, border: '1px solid #ccc', borderRadius: 1 }}>
                                        <Typography variant="h6">Alterar Senha:</Typography>
                                        <Grid container spacing={2} sx={{ mt: 2 }}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                    label="Nova Senha"
                                                    type={showPassword ? 'text' : 'password'}
                                                    value={novaSenha}
                                                    onChange={handlePasswordChange}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={togglePasswordVisibility}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    inputProps={{
                                                        maxLength: 6, // Limita o input para 6 caracteres
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleUpdatePassword(userDetails.id, novaSenha)}
                                                    disabled={loading || novaSenha.length !== 6} // Desabilita o botão se a senha não tiver 6 caracteres
                                                >
                                                    {loading ? <CircularProgress size={24} /> : 'Atualizar Senha'}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    {/* Novo Box para alterar o email */}
                                    <Box sx={{ mt: 4, p: 2, border: '1px solid #ccc', borderRadius: 1 }}>
                                        <Typography variant="h6">Atualizar E-mail:</Typography>
                                        <Grid container spacing={2} sx={{ mt: 2 }}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                    label="E-mail"
                                                    value={novoEmail}
                                                    onChange={handleInputChangeEmail}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleUpdateEmail(userDetails.id, novoEmail)}
                                                    disabled={loading}
                                                >
                                                    {loading ? <CircularProgress size={24} /> : 'Atualizar E-mail'}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    {/* Novo Box para alterar a tarifas */}
                                    <Box sx={{ mt: 4, p: 2, border: '1px solid #ccc', borderRadius: 1 }}>
                                        <Typography variant="h6">Tarifas:</Typography>
                                        <Grid container spacing={2}>
                                            {userDetails.tariffs.map((tariff, index) => {
                                                // Verifica se a tarifa é "tarifa_comissao" e se o perfil é "bet"
                                                if (tariff.tariffkey === "tarifa_comissao" && userDetails.accountData[0]?.profile === 'bet') {
                                                    return null; // Não exibe a tarifa_comissao se o perfil for "bet"
                                                }

                                                return (
                                                    <Grid key={index} item xs={12} sm={6}>
                                                        <Paper elevation={3} sx={{ p: 2, borderRadius: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <Box>
                                                                <Typography variant="body1">
                                                                    {`${tariff.description}`}
                                                                </Typography>
                                                                <Typography variant="body1">
                                                                    {tariff.typeofcharge == 1 ? "Valor: " + Formatar.converterCentavosParaReais(tariff?.value ? tariff.value : 0) : "Valor: " + Formatar.converterCentavosEmPorcentagem(tariff?.value ? tariff.value : 0) + '%'}
                                                                </Typography>
                                                                <Typography variant="body1">
                                                                    {`Tipo de cobrança: ${tariff.typeofcharge == 1 ? "Dinheiro" : "Porcentagem"}`}
                                                                </Typography>
                                                            </Box>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => {
                                                                    setOpenTarifaDialog(true);
                                                                    setDadosAcao(tariff);
                                                                    setDescricao(tariff.description);
                                                                    setTipoCobranca(tariff.typeofcharge == 1 ? 1 : 2);
                                                                    setValor(tariff.value);
                                                                }}
                                                            >
                                                                Editar
                                                            </Button>
                                                        </Paper>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Box>

                                    <Box sx={{ mt: 4, p: 2, border: '1px solid #ccc', borderRadius: 1 }}>
                                        <Typography variant="h6">Tarifa Comissão</Typography>
                                        <Grid container spacing={2} sx={{ mt: 2 }}>
                                            {userDetails.accountData[0]?.operatorid ? (
                                                // Caso o operador já esteja configurado, exibe a mensagem e o botão de alterar
                                                <>
                                                    <Grid item xs={12}>
                                                        <Typography variant="body1">
                                                            Tarifa comissão configurada para o ID: <strong>{userDetails.accountData[0]?.operatorid}</strong>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button
                                                            fullWidth
                                                            variant="contained"
                                                            color="secondary"
                                                            onClick={() => setEditandoBeneficiario(true)} // Ativa o modo de edição
                                                        >
                                                            Alterar Beneficiário
                                                        </Button>
                                                    </Grid>
                                                </>
                                            ) : (
                                                // Caso contrário, exibe o formulário para cadastrar o beneficiário
                                                <>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="normal"
                                                            label="CPF do Beneficiário"
                                                            value={novoCpfComissao}
                                                            onChange={(event) => handleInputChangeCpf(event)}
                                                            inputProps={{ maxLength: 14 }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button
                                                            fullWidth
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => handleUpdateCpf(userDetails.id, novoCpfComissao)}
                                                            disabled={loading}
                                                        >
                                                            {loading ? <CircularProgress size={24} /> : 'Cadastrar Beneficiário'}
                                                        </Button>
                                                    </Grid>
                                                </>
                                            )}

                                            {/* Se estiver no modo de edição, exibe novamente o formulário */}
                                            {editandoBeneficiario && (
                                                <>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="normal"
                                                            label="CPF do Novo Beneficiário"
                                                            value={novoCpfComissao}
                                                            onChange={(event) => handleInputChangeCpf(event)}
                                                            inputProps={{ maxLength: 14 }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Button
                                                            fullWidth
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => handleUpdateCpf(userDetails.id, novoCpfComissao)}
                                                            disabled={loading}
                                                        >
                                                            {loading ? <CircularProgress size={24} /> : 'Atualizar Beneficiário'}
                                                        </Button>
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                    </Box>

                                </AccordionDetails>
                            </Accordion>

                            {chosenbank === "qesh" &&
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>KYC</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2}>
                                            {/* Documento com Foto e a Pessoa (Frente) */}
                                            <Grid item xs={12} sm={6}>
                                                <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
                                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Documento com Foto e a Pessoa (Frente):</Typography>
                                                    {userDetails?.docFaceBase64 ? (
                                                        <img src={`data:image/jpeg;base64,${userDetails?.docFaceBase64}`} alt="Documento com Foto e a Pessoa (Frente)" style={{ width: '100%', borderRadius: '5px' }} />
                                                    ) : (
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            onChange={(e) => handleImageUpload(e, 'docFace')}
                                                        />
                                                    )}
                                                </Paper>
                                            </Grid>

                                            {/* Foto Frontal da Pessoa */}
                                            <Grid item xs={12} sm={6}>
                                                <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
                                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Foto Frontal da Pessoa:</Typography>
                                                    {userDetails?.face1Base64 ? (
                                                        <img src={`data:image/jpeg;base64,${userDetails?.face1Base64}`} alt="Foto Frontal da Pessoa" style={{ width: '100%', borderRadius: '5px' }} />
                                                    ) : (
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            onChange={(e) => handleImageUpload(e, 'face1')}
                                                        />
                                                    )}
                                                </Paper>
                                            </Grid>

                                            {/* Foto Lateral da Pessoa */}
                                            <Grid item xs={12} sm={6}>
                                                <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
                                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Foto Lateral da Pessoa:</Typography>
                                                    {userDetails?.face2Base64 ? (
                                                        <img src={`data:image/jpeg;base64,${userDetails?.face2Base64}`} alt="Foto Lateral da Pessoa" style={{ width: '100%', borderRadius: '5px' }} />
                                                    ) : (
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            onChange={(e) => handleImageUpload(e, 'face2')}
                                                        />
                                                    )}
                                                </Paper>
                                            </Grid>

                                            {/* Documento (Frente) */}
                                            <Grid item xs={12} sm={6}>
                                                <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
                                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Documento de Identidade (Frente):</Typography>
                                                    {userDetails?.frontBase64 ? (
                                                        <img src={`data:image/jpeg;base64,${userDetails?.frontBase64}`} alt="Documento (Frente)" style={{ width: '100%', borderRadius: '5px' }} />
                                                    ) : (
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            onChange={(e) => handleImageUpload(e, 'front')}
                                                        />
                                                    )}
                                                </Paper>
                                            </Grid>

                                            {/* Documento (Verso) */}
                                            <Grid item xs={12} sm={6}>
                                                <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
                                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Documento de Identidade (Verso):</Typography>
                                                    {userDetails?.backBase64 ? (
                                                        <img src={`data:image/jpeg;base64,${userDetails?.backBase64}`} alt="Documento (Verso)" style={{ width: '100%', borderRadius: '5px' }} />
                                                    ) : (
                                                        <input
                                                            type="file"
                                                            accept="image/*"
                                                            onChange={(e) => handleImageUpload(e, 'back')}
                                                        />
                                                    )}
                                                </Paper>
                                            </Grid>

                                            <Grid item xs={12} mb={2}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleSendImageUpload(userDetails)}
                                                >
                                                    {loading ? <CircularProgress size={24} /> : 'Atualizando o KYC'}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                color="primary"
                                                onClick={() => verificarStatusKYC(userDetails.accountData.find((account) => account.chosenbank === chosenbank)?.paymentaccount)}
                                                disabled={loadingKYC}
                                            >
                                                {loadingKYC ? <CircularProgress size={24} /> : 'Verificar Status do KYC'}
                                            </Button>
                                        </Grid>

                                        {/* Exibição do status do KYC */}
                                        {kycStatus && (
                                            <Grid item xs={12}>
                                                <Typography variant="h6" color="textSecondary">
                                                    Status do KYC: {kycStatus}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </AccordionDetails>
                                </Accordion>
                            }

                        </>
                    ) : (
                        <Typography>Carregando detalhes...</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseMasterDialog}>Fechar</Button>
                </DialogActions>
            </Dialog >

            <Dialog open={openDialogevp} onClose={() => setOpenDialogevp(false)}>
                <DialogTitle>Escolha o tipo de chave Pix</DialogTitle>
                <DialogContent>
                    <Typography>Selecione o tipo de chave que deseja criar:</Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={async () => {
                            //  setSelectedTypeKey(0); // CPF
                            await handleCreatePixKey({ typekey: 0, userId: childId, chosenbank: chosenbank }, true);
                            setOpenDialogevp(false);
                        }}
                    >
                        CPF
                    </Button>
                    <Button
                        onClick={async () => {
                            // setSelectedTypeKey(4); // EVP
                            await handleCreatePixKey({ typekey: 4, userId: childId, chosenbank: chosenbank }, true);
                            setOpenDialogevp(false);
                        }}
                    >
                        Aleatória (EVP)
                    </Button>
                    <Button
                        onClick={() => {
                            setSelectedTypeKey(null); // Cancelar
                            setOpenDialogevp(false);
                        }}
                        color="error"
                    >
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>

            <TarifaDialog
                open={openTarifaDialog}
                onClose={fecharModalTariaPadrao}
                dadosAcao={dadosAcao}
                descricao={descricao}
                valor={valor}
                tipoDeCobranca={tipo_de_cobranca}
                onChangeValor={handleChange}
                onChangeValorTipo={handleChangeTipo}
                isLoading={state.loadAlterarTarifaPadrao}
                onAlterarTarifa={alterarTarifa}
                tariffkey={dadosAcao.tariffkey}
            />

            <IsencaoDialog
                open={openIsencaoDialog}
                onClose={handleCloseIsencaoDialog}
                tariff={tariff}
                casadeaposta={casadeaposta}
                login={login}
                password={password}
                motivo={motivo}
                selectedUserIsencao={selectedUserIsencao}
                onChangeAposta={(event) => setcasadeaposta(event.target.value)}
                onChangeLogin={(event) => setlogin(event.target.value)}
                onChangePassword={(event) => setpassword(event.target.value)}
                onChangeMotivo={(event) => setmotivo(event.target.value)}
                onSolicitarIsencao={handleSolicitarIsencaoDialog}
                formatDocumento={formatDocumento}
            />

            {/* Snackbar para exibição de mensagens */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{
                    width: '100%',
                    fontSize: '1.50rem', // Aumenta em 15%
                }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>

            {/* Loading Spinner */}
            {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <CircularProgress />
                </Box>
            )}
        </>
    );
};

export default DataTable;
