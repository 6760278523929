import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField, Box, Button, CircularProgress, FormControl, Select, MenuItem, InputLabel } from '@mui/material';

const TarifaDialog = ({
  open,
  onClose,
  dadosAcao,
  descricao,
  valor,
  tipoDeCobranca,
  onChangeValor,
  isLoading,
  onAlterarTarifa,
  onChangeValorTipo,
  tariffkey
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      fullWidth
      maxWidth="md" // Tamanhos disponíveis: "xs", "sm", "md", "lg", "xl"
    >
      <DialogTitle id="dialog-title">{"Editar " + dadosAcao.description}</DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              margin="normal"
              label="Descrição"
              value={descricao}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              margin="normal"
              label={tipoDeCobranca === 1 ? "Valor R$" : "Valor %"}
              value={valor}
              onChange={onChangeValor}
            />
          </Grid>

          {
            tariffkey !== 'tarifa_saque_conta_gerente' ? (
              <Grid item xs={12} sm={4}>
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  label="Tipo de cobrança"
                  value={tipoDeCobranca === 1 ? "Dinheiro" : "Porcentagem"}
                  disabled
                />
              </Grid>
            ) : (
              <Grid item xs={12} sm={4}>
                <FormControl size="small" variant="outlined" margin="normal" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">Tipo de cobrança</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={tipoDeCobranca}
                    onChange={onChangeValorTipo}
                    label="Tipo de cobrança"
                  >
                    <MenuItem value="">
                      <em>Escolha</em>
                    </MenuItem>
                    <MenuItem value={1}>Dinheiro</MenuItem>
                    <MenuItem value={2}>Porcentagem</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )
          }
        </Grid>
      </DialogContent>

      <DialogActions>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
            padding: '8px 16px',
          }}
        >
          <Button
            size="small"
            variant="contained"
            color="error"
            sx={{
              height: '40px',
              fontSize: '0.875rem',
              position: 'relative',
            }}
            onClick={onClose}
          >
            Voltar
          </Button>

          <Button
            size="small"
            variant="contained"
            color="success"
            sx={{
              height: '40px',
              fontSize: '0.875rem',
              position: 'relative',
            }}
            onClick={onAlterarTarifa}
            disabled={isLoading}
          >
            {isLoading && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
            {isLoading ? 'Carregando...' : 'Alterar'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default TarifaDialog;
